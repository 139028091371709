import { useWallet } from "@terra-money/use-wallet";
import { UserData } from "models/Interfaces";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { instanceOfUserData } from "utils/instanceOf";

/* Interface creation */
export interface UserDataContext {
  userData: UserData | null;
  isLoggedIn: boolean | null;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | null>>
  setUser(user?: UserData | null, isLoggedInStatus?: boolean): void;
  getUser(): void;
}

/* Context */
const UserContext = createContext<UserDataContext>({
  userData: null,
  isLoggedIn: null,
  setUser: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
  getUser: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
  setIsLoggedIn: () => {
    throw "Requesting component not nested inside UserContextProvider";
  },
});

/* Using Context*/
export const useContextUser = () => useContext(UserContext);

/* Provider fn */
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const { status } = useWallet();

  const setUser = (user: UserData | null, isLoggedInStatus?: boolean) => {
    setUserData(user);
    setIsLoggedIn(isLoggedInStatus || isLoggedIn);
  };

  const getUser = async () => {
    if (userData === null) {
      try {
        const connection = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/auth/isLoggedIn`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const dataFromApi = await connection.json();
        if (
          typeof dataFromApi.data === "object" &&
          instanceOfUserData(dataFromApi.data)
        ) {
          setUserData(dataFromApi.data);
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoggedIn(false);
      }
    }
  };

  useEffect(() => {
    getUser();
  }, [status]);

  const dataUser = {
    userData: userData,
    isLoggedIn: isLoggedIn,
    setIsLoggedIn,
    setUser,
    getUser,
  };

  return (
    <UserContext.Provider value={dataUser}>{children}</UserContext.Provider>
  );
};

import { useContextBlockchainData } from "contexts/blockchain-data";
import {
  NewNotificationFlowStep,
  useNewNotificationFlow,
} from "contexts/new-notification-flow";
import { BlockchainType, NotificationType } from "models/Enums";
import { useTranslation } from "react-i18next";

import PopUp, { PopupSize } from "../../../Popup";
import BlockchainStep from "./BlockchainStep";
import { ConfigurationStep } from "./ConfigurationStep";
import NotificationAlertTypeStep from "./NotificationTypeStep";

function NewNotificationFlow() {
  const { t } = useTranslation(["common", "enumerations"]);
  const { blockchains } = useContextBlockchainData();
  const newNotificationFlow = useNewNotificationFlow();

  if (!newNotificationFlow.isActive) {
    return null;
  }
  const showImageBlockchain = (blockchainId: string) => {
    const chain = blockchains?.find((b) => b.chain_id === blockchainId);

    const nameChain = Object.entries(BlockchainType).find(
      ([key, value]) => value === blockchainId && key
    );

    const imageURL =
      (chain?.chain_id === BlockchainType.TerraClassic &&
        "https://assets.hermesprotocol.io/img/projects/TerraClassic.png") ||
      (chain?.chain_id === BlockchainType.Terrav2 && "https://assets.hermesprotocol.io/img/projects/Terra.png");
      
    return (
      <img
        src={imageURL || chain?.image_url}
        width={50}
        height={50}
        className={"mx-1"}
        alt={
          nameChain &&
          t(`blockchains_types.${nameChain[0]}`, {
            ns: "enumerations",
          })
        }
      />
    );
  };

  const resizeWindow = () => {
    const notifType = newNotificationFlow.selectedNotificationType?.slice(-4);

    return NewNotificationFlowStep.notificationConfigStep ===
      newNotificationFlow.currentStep &&
      notifType === NotificationType.NftMonitor
      ? true
      : false;
  };

  return (
    <PopUp size={resizeWindow() ? PopupSize.largeXL : PopupSize.large}>
      {newNotificationFlow.currentStep !==
        NewNotificationFlowStep.notificationConfigStep && (
        <div className="flex justify-between items-center pb-4 border-b border-black-line w-full ">
          <p className="text-xl font-bold">
            {t("notifications.new_notification_title", { ns: "common" })}
          </p>
          {newNotificationFlow.selectedNotificationBlockchain &&
            showImageBlockchain(
              newNotificationFlow.selectedNotificationBlockchain
            )}
        </div>
      )}
      {newNotificationFlow.currentStep ===
        NewNotificationFlowStep.BlockchainStep && (
        <BlockchainStep
          handleCancel={newNotificationFlow.cancelCreateNewNotification}
          handleGoToNext={newNotificationFlow.goToNotificationAlertTypes}
        />
      )}
      {newNotificationFlow.currentStep ===
        NewNotificationFlowStep.alertTypeStep && (
        <NotificationAlertTypeStep
          handleCancel={newNotificationFlow.cancelCreateNewNotification}
          handleGoToNext={newNotificationFlow.goToNotificationConfigs}
          handleGoBack={newNotificationFlow.goBack}
          blockchain={newNotificationFlow.selectedNotificationBlockchain!}
        />
      )}

      {/* handleGoToNext if not has socials, this will the last step */}

      {newNotificationFlow.currentStep ===
        NewNotificationFlowStep.notificationConfigStep && (
        <ConfigurationStep
          handleCancel={newNotificationFlow.cancelCreateNewNotification}
          handleGoToNext={newNotificationFlow.submitNewNotification}
          handleGoBack={newNotificationFlow.goBack}
          currentCategory={newNotificationFlow.selectedCategoryType!}
          currentPlatform={newNotificationFlow.selectedPlatformType!}
          currentType={newNotificationFlow.selectedNotificationType!}
          blockchain={newNotificationFlow.selectedNotificationBlockchain!}
          isCreatingNotification={newNotificationFlow.creatingNotification}
        />
      )}
    </PopUp>
  );
}

export default NewNotificationFlow;

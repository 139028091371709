import { Fee, MsgExecuteContract, MsgSend } from "@terra-money/terra.js";
import {
  SignBytesFailed,
  SignBytesResult,
  SignResult,
  Timeout,
  useConnectedWallet,
  UserDenied,
  verifyBytes,
} from "@terra-money/wallet-provider";
import classNames from "classnames";
import { ConnectWallet, WalletTypes } from "components/Auth/ConnectWallet";
import Button, { ButtonSize, ButtonType } from "components/Button";
import Loader, { LoaderType } from "components/Loader";
import Logo from "components/Logo";
import { useContextUser } from "contexts/user";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { setTimeout } from "timers";
import { instanceOfUserData } from "utils/instanceOf";
import { ModalState, Network } from "views/SignIn";
import { Warning } from "./Warning";
import jscrypto from "jscrypto";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { terra } from "shared/Terra";

export enum WalletSettingsState {
  ongoing = "ongoing",
  connectWallet = "connectWallet",
  walletVerified = "walletVerified",
  hasWallet = "hasWallet",
  verifyFailure = "verifyFailure",
}

export const WalletsFlow: React.FC<{
  closeModalMain?: Dispatch<SetStateAction<any>>;
}> = ({ closeModalMain }) => {
  const { t } = useTranslation(["common"]);
  const connectedWallet = useConnectedWallet();
  const { userData, setUser } = useContextUser();
  const { blockchains } = useContextBlockchainData();

  const [width, setWidth] = useState(window.innerWidth);
  const [modalContent, setModalContent] = useState<
    ModalState | WalletSettingsState
  >(WalletSettingsState.ongoing);
  const [newWallet, setNewWallet] = useState<null | string>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [randomBytes, setRandomBytes] = useState("");
  const [txError, setTxError] = useState<string | null>(null);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const connectWallet = async () => {
    setModalContent(WalletSettingsState.connectWallet);
    setIsOpenModal(true);
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const hasWallet = (): boolean => {
    const res = userData?.wallets.filter((wallet) => {
      return wallet.walletAddress === connectedWallet?.terraAddress;
    });
    return (res && res.length > 0) || false;
  };

  const handleVerification = async () => {
    /* Upon intention to verify wallet, ask the API for bytes for that wallet */
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/auth/bytes`,
        {
          method: "POST",
          body: JSON.stringify({
            walletAddress: connectedWallet?.terraAddress,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      setRandomBytes(dataFromApi.data.bytes);
      if (connectedWallet?.connection.identifier === WalletTypes.station) {
        await signBytes(dataFromApi.data.bytes);
      } else if (
        connectedWallet?.connection.identifier !== WalletTypes.station
      ) {
        await executeContract();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signBytes = useCallback(
    async (originalBytes) => {
      if (!connectedWallet) {
        return;
      }
      try {
        const bufferedBytes = Buffer.from(randomBytes);
        /* Sign bytes with bytes fetched from API to this specific wallet */
        const signedBytes: SignBytesResult = await connectedWallet.signBytes(
          bufferedBytes
        );
        let _a;
        const publicKey =
          (_a = signedBytes.result.public_key) === null || _a === void 0
            ? void 0
            : _a.toProto();
        const bytes = Buffer.from(
          jscrypto.SHA256.hash(
            new jscrypto.Word32Array(bufferedBytes)
          ).toString(),
          "hex"
        );
        /* Send information from signing to API to verify the transaction */
        let result;
        try {
          const connection = await fetch(
            `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/auth/verifySig`,
            {
              method: "POST",
              body: JSON.stringify({
                bytes,
                signBytesResult: signedBytes.result,
                publicKey,
                walletAddress: connectedWallet.terraAddress,
                originalBytes,
              }),
              credentials: "include",
            }
          );
          const dataFromApi = await connection.json();
          result = dataFromApi.data.result;
        } catch (error) {
          console.error(error);
        }
        if (result && !hasWallet()) {
          try {
            const connection = await fetch(
              `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/wallets`,
              {
                method: "POST",
                body: JSON.stringify({
                  userId: userData?.userId,
                  walletAddress: connectedWallet.terraAddress,
                  walletAlias: "",
                  chainId:
                    blockchains?.filter(
                      (b) =>
                        b.chain_tecnical_id === connectedWallet.network.chainID
                    )[0].chain_id || "zb1_002",
                }),
                credentials: "include",
              }
            );
            const dataFromApi = await connection.json();
            if (
              typeof dataFromApi.data === "object" &&
              instanceOfUserData(dataFromApi.data)
            ) {
              setUser(dataFromApi.data);
              setModalContent(WalletSettingsState.walletVerified);
            } else if (dataFromApi.data === "elementExists") {
              setModalContent(WalletSettingsState.hasWallet);
            } else {
              setModalContent(ModalState.failure);
            }
          } catch (error) {
            console.error(error);
          }
        } else if (result && hasWallet()) {
          setModalContent(WalletSettingsState.hasWallet);
        } else {
          setModalContent(WalletSettingsState.verifyFailure);
        }
      } catch (error) {
        if (error instanceof UserDenied) {
          setTxError("User Denied");
        } else if (error instanceof Timeout) {
          setTxError("Timeout");
        } else if (error instanceof SignBytesFailed) {
          setTxError("Sign Bytes Failed");
        } else {
          setTxError(
            "Unknown Error: " +
              (error instanceof Error ? error.message : String(error))
          );
        }
        handleModal(WalletSettingsState.verifyFailure);
      }
    },
    [connectedWallet]
  );

  const getContract = (contract: string) => {
    switch (contract) {
      case Network.columbus5:
        return process.env.REACT_APP_CONTRACT_TERRA_CLASSIC || "";
      case Network.phoenix1:
        return process.env.REACT_APP_CONTRACT_TERRA || "";
      default:
        return process.env.REACT_APP_CONTRACT_TERRA || "";
    }
  };

  const executeContract = async () => {
    if (connectedWallet) {
      let executeTx: SignResult;
      try {
        const execute = new MsgExecuteContract(
          connectedWallet.walletAddress,
          getContract(connectedWallet?.network.chainID),
          { login: {} }
        );

        executeTx = await connectedWallet.sign({
          msgs: [execute],
        });

        const executeTxResult = await terra.tx.broadcast(executeTx.result);
        // if (executeTx) {
        //   /* If wallet is connected and verified, fetch wallet address and send to setUser function */
        //   setLoggedInUser(wallets[0].terraAddress);
        // }

        // if (!executeTx) {
        //   /* alert("Could not sign transaction properly"); */
        //   /* If wallet is connected but not correctly verified, send to setUser function information to not allow auths */
        //   setModalContent(ModalState.failure);
        //   setIsOpenModal(true);
        //   return;
        // }
      } catch (error: unknown) {
        handleModal(ModalState.failure);
      }
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      closeModalMain && closeModalMain(false);
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div>
      {modalContent === WalletSettingsState.ongoing && (
        <>
          <h4 className="p-2 relative -left-2 text-center ">
            {t("settings.signinWalletTitle", { ns: "common" })}
          </h4>

          <div className="flex flex-row flex-nowrap	justify-center">
            <div className="flex flex-col relative">
              <div
                className={classNames(
                  "h-16  mt-2 flex items-center justify-center"
                )}
              >
                <Button
                  onClick={connectWallet}
                  type={ButtonType.secondary}
                  size={
                    width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                  }
                  fullWidth
                  disabled={newWallet !== null}
                >
                  <img
                    src="./images/terra_icon.svg"
                    className={classNames("inline")}
                    alt="Terra Wallet"
                  />
                  <span className="pl-3 md:pl-6 w-40">
                    {t(`wallet_action.connect`, { ns: "common" })}
                  </span>
                </Button>
              </div>
              <div className="h-16 my-2 flex items-center justify-center">
                <Button
                  onClick={handleVerification}
                  type={ButtonType.secondary}
                  size={
                    width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                  }
                  fullWidth
                  disabled={newWallet === null}
                >
                  <img
                    src="./images/terra_icon.svg"
                    className={classNames("inline")}
                    alt="Terra Wallet"
                  />
                  <span className="pl-3 md:pl-6 w-40">
                    {t(`wallet_action.verify`, { ns: "common" })}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {modalContent === WalletSettingsState.connectWallet && (
        <ConnectWallet
          setIsOpenModal={setIsOpenModal}
          setModalContent={setModalContent}
          modalContent={modalContent}
          walletFlow={true}
          setNewWallet={setNewWallet}
          newWallet={newWallet}
        />
      )}
      {modalContent === ModalState.failure && (
        <>
          <Warning
            title={"Warning"}
            text={t("modal_content.failure.verify_wallet", {
              ns: "enumerations",
            })}
            iconWarning={true}
          />
          <div className="text-right cursor-pointer">
            <p
              className="text-base underline text-gold"
              onClick={() => {
                setIsOpenModal(false);
                connectWallet();
              }}
            >
              {t("modal_content.warning.try_again", {
                ns: "enumerations",
              })}
            </p>
          </div>
        </>
      )}
      {modalContent === WalletSettingsState.hasWallet && (
        <>
          <Warning
            title={"Warning"}
            text={t("modal_content.has_wallet.modal_title", {
              ns: "enumerations",
            })}
            iconWarning={true}
          />

          {closeModal()}
        </>
      )}
      {modalContent === WalletSettingsState.walletVerified && (
        <>
          <Warning
            title={"Congratulations"}
            text={t("modal_content.new_wallet_added", {
              ns: "enumerations",
            })}
            colorIcon={"bg-gold"}
            iconSuccess={true}
          />
          {closeModal()}
        </>
      )}
    </div>
  );
};

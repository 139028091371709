import { faDiscord, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { useContextUser } from "contexts/user";
import { SocialChannel } from "models/Enums";
import { UserNotification } from "models/Interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SocialChannels = ({
  socialChannelsData,
  setSocialChannels,
  notification,
}: {
  socialChannelsData: SocialChannel[];
  setSocialChannels: Dispatch<SetStateAction<SocialChannel[]>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const { userData } = useContextUser();
  // draft save
  const [statusNotification, setStatusNotification] = useState<boolean>(true);
  const newNotificationFlow = useNewNotificationFlow();
  const [checkDiscord, setCheckDiscord] = useState(false);
  const [checkTelegram, setCheckTelegram] = useState(false);

  useEffect(() => {
    saveChannels();
  }, []);

  useEffect(() => {
    setStatusInFlow();
  }, [statusNotification]);

  const setStatusInFlow = () => {
    newNotificationFlow.setStatusNotification(statusNotification ? "1" : "0");
  };

  const handleSelectedNotificationTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const socialChannelType = event.target.name as SocialChannel;

    socialChannelType === SocialChannel.Discord &&
      setCheckDiscord(!checkDiscord);
    /*  : setCheckTelegram(!checkTelegram); */
  };

  const hasSocialAccount = (channel?: string): boolean => {
    if (!userData?.socials?.length) {
      return false;
    }

    if (channel) {
      return userData?.socials.filter(
        (c) => c.type.toUpperCase() === channel && c.status !== 0
      ).length !== 0
        ? true
        : false;
    }
    return true;
  };

  const handleClickSetDraft = () => {
    statusNotification
      ? setStatusNotification(false)
      : setStatusNotification(true);
  };

  const saveChannels = () => {
    const getSocials = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );

    if (getSocials.length > 0) {
      const chosenChannels = [...getSocials]?.map((el) => {
        return el?.getAttribute("name") as SocialChannel;
      });
      chosenChannels.length && setSocialChannels(chosenChannels);
    }

    /*
    
         Thinking about the future
        return userData?.socials?.map((socialUser) => {
          return (
            socialUser.type.toLocaleLowerCase() ===
              el?.getAttribute("name")?.toLocaleLowerCase() && socialUser?.id
          );
        });
      });
      chosenChannels?.length && setSocialChannels(chosenChannels);
    */
  };

  useEffect(() => {
    if (notification) {
      notification.discordId.length > 0 && setCheckDiscord(true);
      /*   notification.telegramId.length > 0 && setCheckTelegram(true); */
    } else {
      statusNotification &&
        hasSocialAccount(SocialChannel.Discord) &&
        setCheckDiscord(true);
      /*   statusNotification &&
        hasSocialAccount(SocialChannel.Telegram) &&
        setCheckTelegram(true); */
    }
  }, []);

  useEffect(() => {
    let socials = [];
    if (checkDiscord) {
      socials.push(SocialChannel.Discord);
    }
    /*  if (checkTelegram) {
      socials.push(SocialChannel.Telegram);
    } */
    setSocialChannels(socials);
  }, [checkDiscord, checkTelegram]);

  return (
    <div className="w-full">
      <p className="sm:mt-6 mt-3 px-2 font-semibold mb-3">
        {t(`social_channels`, { ns: "common" })}
      </p>
      <div className="flex justify-center items-center mb-2">
        <div className="w-5/6">
          {Object.values(SocialChannel).map((channelKey) => {
            const channel = channelKey as SocialChannel;
            return (
              <div
                key={channel}
                className="flex items-center justify-between my-2"
              >
                <div>
                  {getNotificationIcon(channel)}
                  {t(`social_channels.${channel}`, { ns: "enumerations" })}
                </div>

                <label className="switch">
                  <input
                    type="checkbox"
                    name={channel}
                    onChange={handleSelectedNotificationTypeChange}
                    disabled={!hasSocialAccount(channel)}
                    checked={
                      channel === SocialChannel.Discord && checkDiscord
                      /* : checkTelegram */
                    }
                    onClick={handleClickSetDraft}
                  />

                  <span
                    className={` ${
                      !hasSocialAccount(channel) && "opacity-70"
                    } slider round checked:bg-black`}
                  ></span>
                </label>
              </div>
            );
          })}
        </div>
      </div>

      {!hasSocialAccount() && (
        <div className="text-black text-xs mb-6">
          {t("notifications.no_social", { ns: "common" })}
        </div>
      )}
    </div>
  );
};

const getNotificationIcon = (channel: SocialChannel) => {
  switch (channel) {
    case SocialChannel.Discord:
      return <FontAwesomeIcon className="text-discord mr-4" icon={faDiscord} />;
    /* case SocialChannel.Twitter:
        return <FontAwesomeIcon className="text-twitter mr-4" icon={faTwitter} />;*/
    /*   case SocialChannel.Telegram:
      return (
        <FontAwesomeIcon className="text-telegram mr-4" icon={faTelegram} />
      ); */
  }
};

export default SocialChannels;

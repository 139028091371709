import classNames from "classnames";
import React, { Dispatch, SetStateAction } from "react";
import BackDrop from "./BackDrop";

export enum PopupSize {
  smallxs = "small-xs",
  small = "small",
  medium = "medium",
  large = "large",
  largeXL = "largeXL",
}

const PopUp: React.FC<{
  children: React.ReactNode;
  closeModal?: Dispatch<SetStateAction<any>>;
  size?: PopupSize;
}> = ({ children, size = PopupSize.medium, closeModal }) => {
  return (
    <>
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex ">
        <div onClick={closeModal}>
          <BackDrop />
        </div>
        <div
          className={classNames(
            "bg-white w-11/12 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto py-6 text-left",
            {
              "flex flex-col items-center justify-evenly max-w-xl px-6":
                size === PopupSize.large,
            },
            {
              "flex flex-col items-center justify-evenly max-w-3xl w-full px-6":
                size === PopupSize.largeXL,
            },
            { "max-w-lg px-6": size === PopupSize.medium },
            { "max-w-sm": size === PopupSize.small },
            { "max-w-xs px-3": size === PopupSize.smallxs }
          )}
          id="pop-up"
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default PopUp;

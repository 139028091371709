import { terra, terraClassic } from "shared";
import { Pagination } from "@terra-money/terra.js/dist/client/lcd/APIRequester";
import { LCDClient, Validator } from "@terra-money/terra.js";
import { BlockchainType } from "models/Enums";
import {
  CollectInfo,
  ContractsCW20,
  Nft,
  Token,
  TokensCW20,
} from "models/Interfaces";
import { nftUrl } from "components/Notifications/Utils/shared";
import { regex } from "components/Notifications/NotificationsType/WalletWatcher";

export const selectCurrentBlockchain = (protocol: string) => {
  return protocol === BlockchainType.TerraClassic ? terraClassic : terra;
};

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// check wallet is valid
export const isValidWallet = (wallet: string): boolean | undefined => {
  if (wallet === "") return;
  return regex.test(wallet) ? true : false;
};

/* FUNCTIONS - PROFILE CHANGES */
export const getValidators = async (
  blockchain: BlockchainType
): Promise<Validator[]> => {
  let finished = false;

  const validators: Validator[] = [];
  let offset: string | undefined = undefined;
  while (!finished) {
    const valds: [Validator[], Pagination] = await selectCurrentBlockchain(
      blockchain
    )?.staking.validators({
      "pagination.key": offset,
    });
    valds[0].forEach((v) => validators.push(v));
    offset = valds[1].next_key ?? undefined;
    if (offset === undefined) {
      finished = true;
    }
    await sleep(1000);
  }

  return validators;
};

export const getDelegationsByWalletAddress = async (
  walletAddress: string,
  blockchain: BlockchainType
): Promise<string[]> => {
  const protocol = selectCurrentBlockchain(blockchain);
  const delegations = await protocol.staking.delegations(walletAddress);
  return delegations[0].map((delegation) => delegation.validator_address);
};

/* FUNCTIONS - PRICE ALERT
 */
// get promise tokens and contracts and return data (contracts assets and tokens) needs
export const formatDataTokens = async (blockchain: string) => {
  const ContractsAndTokens = await getDataTokens(blockchain as BlockchainType);
  const arrAux: any = [];

  return ContractsAndTokens.map(async (el: any) => {
    const DATA = await el;
    const contractsArr = DATA?.map((object: any) => {
      const result = Object.entries(object).filter(([key, value]: any) => {
        if (key === "asset_infos") {
          return value?.map((assets: {}) => {
            const data = Object.values(assets).map((cryptos: any) => {
              const assets_info = Object.entries(cryptos).reduce(
                (ac: any[], crypto): any => {
                  if (!arrAux.length) arrAux.push(crypto[1]);
                  else ac.push(...[arrAux.pop(), crypto[1]]);
                  return ac;
                },
                []
              );
              return assets_info.length !== 0 && assets_info;
            })?.[0];

            return data !== false && data;
          })?.[1];
        }
      })?.[0];

      return result !== undefined
        ? { [object?.contract_addr]: result[1] }
        : object;
    });

    return contractsArr;
  });
};

export const removeTokensUndefined = async (dataTokens: any) => {
  return await dataTokens?.map((dt: {}) => {
    return Object.entries(dt)?.filter(([contract, pairs]: any) => {
      if (
        contract !== undefined &&
        pairs[0] !== undefined &&
        pairs[1] !== undefined
      ) {
        return { [contract]: [pairs[0], pairs[1]] };
      } else {
        return false;
      }
    })[0];
  });
};
// create array with contractsAddr and respectives tokens
export const formatPresentation = async (blockchain: string) => {
  const arrData = await formatDataTokens(blockchain);
  const contracts = await arrData[0];
  const tokens = await arrData[1];

  const dataTokens = contracts.map((contract: any) => {
    const dataContracts = Object.values(contract).map((c: any) => {
      return Object.values(c)?.map((crypto1: any) => {
        const eachToken: any = Object.values(crypto1)?.[0];

        return Object.values(eachToken).map((value: any) => {
          const dataFilter = tokens.filter((t: any) => {
            return Object.values(t)?.includes(value);
          })?.[0];
          return dataFilter && { ...dataFilter };
        })?.[0];
      });
    });

    return { [String(Object.keys(contract))]: { ...dataContracts[0] } };
  });
  return dataTokens;
};

export const getInfoCW20ByBlockchain = async (
  protocol: string
): Promise<any> => {
  const endpointContracts =
    protocol === BlockchainType.TerraClassic
      ? `${process?.env.REACT_APP_CONTRACT_LUNC_URL}`
      : `${process?.env.REACT_APP_CONTRACT_LUNA_URL}`;
  const endpointTokens =
    protocol === BlockchainType.TerraClassic
      ? `${process?.env.REACT_APP_TOKENS_LUNC_URL}`
      : `${process?.env.REACT_APP_TOKENS_LUNA_URL}`;

  try {
    const contracts = await fetch(`${endpointContracts}`, { method: "GET" });
    const tokens = await fetch(`${endpointTokens}`, { method: "GET" });
    if (!contracts.ok || !tokens.ok) {
      !contracts.ok &&
        console.error(
          `Hermes-Protocol says: Error fetching  ${contracts.status} from ${contracts.url}`
        );
      !tokens.ok &&
        console.error(
          `Hermes-Protocol says: Error fetching  ${tokens.status} from ${tokens.url}`
        );
      return false;
    }
    return [contracts.json(), tokens.json()];
  } catch (error) {
    return false;
  }
};

export const getDataTokens = async (protocol: BlockchainType) => {
  // Get all data from git [ContractsCW20 and TokensCW20]
  const ALL_DATA = await getInfoCW20ByBlockchain(protocol);

  // Passing on key and value in order to access promise
  return ALL_DATA.map((contractsAndTokens: any) => {
    return contractsAndTokens.then((data: ContractsCW20 | TokensCW20) => {
      const result = Object.entries(data).map(([k, v]: any) => {
        /* ((key === "tokens" && (k === "icon" || k === "name")) || */

        return Object.fromEntries(
          Object.entries(v).filter(
            ([name, value]) =>
              (name === "icon" ||
                name === "name" ||
                name === "denom" ||
                name === "token" ||
                name === "symbol" ||
                name === "contract_addr" ||
                name === "protocol" ||
                name === "asset_infos") &&
              value
          )
        );
      });

      return [...result];
    });
  });
};

/* FUNCTIONS - NFT */

export const getInfoNFTByContract = async (
  contract: string,
  protocol: string,
  query: string = `{"all_tokens":{}}`
) => {
  // terra Classic API - OLD
  // Luna new - mainnet
  const endpointAux =
    protocol.toLocaleLowerCase() === "classic"
      ? `https://cdn.luart.io/mainnet/${contract}/nft-compact-metadata.json`
      : `https://assets.hermesprotocol.io/blockchains/terra/nfts/metadata/${contract}.json`;

  try {
    return fetch(endpointAux, { method: "GET" }).then((data) => {
      if (data.status !== 200) {
        console.error(
          `Hermes-Protocol says: Error fetching ${data.status} from ${endpointAux}`
        );
        return false;
      }
      return data.json();
    });
  } catch (e) {
    return false;
  }
};

export const getNftByCollection = async (
  contract: string,
  blockchain: string
) => {
  const tokens = await getInfoNFTByContract(contract, blockchain);

  if (!tokens) {
    return [];
  }

  // temp code
  const transformTypeTokensByChain = Array.isArray(tokens)
    ? tokens
    : Object.values(tokens); // waiting for DuDucks (Classic list NFTs)

  return transformTypeTokensByChain?.map((value: Token) => {
    const obj =
      blockchain === "classic"
        ? { image: value.imageURL, name: value.name, tokenId: value.tokenId }
        : {
            image: nftUrl(value.image),
            name: value.name,
            tokenId: value.tokenId,
          }; //temp
    return Object.assign({}, obj);
  });
};

export const getDataCollectionBlockchain = async (
  contract: string,
  protocol: string
): Promise<any> => {
  const blockchain = selectCurrentBlockchain(protocol);
  return await blockchain.wasm.contractInfo(contract);
};

// depois de ler as coleções da url , buscar em cada contract se para essa wallet tem nfts
export const getUsersNft = async (
  blockchainObj: LCDClient,
  nftAddress: string,
  collections: CollectInfo[]
): Promise<any> => {
  const result: any =
    collections &&
    Object.keys(collections).map(async (contract: string) => {
      try {
        const infoToken: Array<[{}]> = await blockchainObj.wasm.contractQuery(
          `${contract}`,
          {
            tokens: { owner: `${nftAddress}` },
          }
        );

        if (Object.values(infoToken)?.[0].length > 0) {
          const dataTosave = {
            [contract]: { ...Object.values(infoToken)?.[0] },
          };
          const userNftListing = localStorage.getItem("user_nft_listing");
          if (userNftListing !== null) {
            Object.assign(dataTosave, JSON.parse(userNftListing));
            localStorage.removeItem("user_nft_listing");
          }
          localStorage.setItem("user_nft_listing", JSON.stringify(dataTosave));
        }
      } catch (e) {
        console.error(e);
      }
    });
  return result;
};

export const getCollections = async (blockchain: BlockchainType) => {
  console.log(blockchain);
  const buildURL =
    blockchain === BlockchainType.TerraClassic
      ? `${process.env.REACT_APP_CONTRACTS_NFT_LUNC_URL}`
      : blockchain === BlockchainType.Terrav2
      ? `${process.env.REACT_APP_CONTRACTS_NFT_LUNA_URL}`
      : `${process.env.REACT_APP_CONTRACTS_NFT_NEAR}`;
  try {
    const result = await fetch(`${buildURL}`, { method: "GET" });
    if (!result.ok) {
      console.error(
        `Hermes-Protocol says: Error fetching ${result.status} from ${buildURL}`
      );
      return false;
    }
    return result.json();
  } catch (e) {
    return false;
  }
};

export const getNftListingLocalStorage = () => {
  return localStorage.getItem("user_nft_listing");
};

export const loadingNFTUserByCollection = async (
  blockchain: BlockchainType,
  walletAddress: string,
  collectionList: CollectInfo[]
) => {
  const blockchainObj = selectCurrentBlockchain(blockchain);
  await getUsersNft(blockchainObj, walletAddress, collectionList);

  return getNftListingLocalStorage();
};

// created array in order to  collections
/* export const structureCollection = (collections: CollectInfo[],
): CollectInfo[] => {


  const collectionsFormatted: any = Object.entries(collections)?.map(([chainId, contracts]) => {
    const contractArray = Object.entries(contracts);
    return contractArray.map(([contract, data]) => {
      
      return {
        chainId,
        ...data,
      };
    });
  }); 

  return [].concat.apply([], collectionsFormatted);

export const test = async () => {
  const address = "terra1f9c8jucrknme3mryzts3nhr2zgh589uvdu45uy";
  const [balance] = await terra.bank.balance(address);
  const offerCoin = new Coin("uusd", "100000");
  const result = await terraClassic.market.swapRate(offerCoin, "axlUSDC");
  /*  console.log(
    ${offerCoin.toString()} can be swapped for ${result.toString()}
      return balance.toString();
  ); 


};
 */

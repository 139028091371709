import React, { useEffect, useState } from "react";
import {
  PlatformType,
  SocialChannel,
  NotificationType,
  ContractPairs,
  ScopeNotification,
} from "models/Enums";
import Notification, { AlertConfiguration } from "models/Notification";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCopy } from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faFacebookF,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import PlatformIcon from "./PlatformIcon";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { Nft, UserData, UserNotification } from "models/Interfaces";
import { shortURL } from "./Notifications/Utils/shared";
import { Tooltip } from "./Tooltip";

export interface INotificationProps {
  notification: Notification;
}

const NotificationCard: React.FC<{
  key: number;
  setOpacity: boolean;
  notification: UserNotification;
  handleOnClick(): void;
}> = ({ key, setOpacity, notification, handleOnClick }) => {
  const { t } = useTranslation(["common", "enumerations", ""]);

  const { types, projects, blockchains } = useContextBlockchainData();
  const [copy, setCopy] = useState(false);

  const getTypeDesc = () => {
    return types?.filter(
      (t) => t?.notification_type_id === notification?.notificationTypeId
    )[0]?.notification_type_desc;
  };

  const getWalletAddress = () =>
    (notification && notification?.walletAddress) || "";
  const platformByType = () => {
    return projects?.filter((p) => {
      return p?.project_market_id === notification?.projectId;
    })[0].project_market_desc;
  };

  const copyToClipboard = (word?: string) => {
    navigator.clipboard.writeText(word || "");
    setCopy(true);
  };
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 2000);
    }
  }, [copy]);

  const showMonitor = () => {
    const nft = notification?.nft as Nft[];

    return Object.entries(nft).map(([key, value]) => {
      return key === "name" && value;
    });
  };

  return (
    <div className="w-full p-4 flex sm:w-1/2 lg:w-1/3 xl:w-1/4 min-h-xs">
      {}
      <div
        className={`w-full relative rounded-2xl bg-white shadow-white shadow-lg overflow-hidden p-4 ${
          setOpacity && "bg-gray-extralight50"
        }`}
      >
        <div className={`flex flex-col`}>
          <header className="flex justify-between mb-3 z-10">
            <span className="h-8 w-8">
              <PlatformIcon
                className="h-8 w-8"
                platform={notification?.projectId}
                blockchain={notification?.chainId}
              />
            </span>
            <button
              onClick={handleOnClick}
              className={` h-9 w-9 ${
                !setOpacity && "shadow-inner-2xl"
              } bg-white text-gold rounded-full hover:shadow-inner-2xl cursor-pointer transition duration-200`}
              aria-label="Edit notification"
            >
              <FontAwesomeIcon icon={faCog} className="bg-transparent" />
            </button>
          </header>
          <div className="flex flex-col justify-between z-10">
            <h4 className="mb-6">{getTypeDesc()}</h4>
            {notification?.notificationTypeId ===
              NotificationType?.GovernancePoll && (
              <h5 className="">{platformByType()}</h5>
            )}
            {/* // Se for my Myeallet: myWallet, para UNIQUE icon e nome */}

            {notification?.notificationTypeId !==
              NotificationType?.GovernancePoll && (
              <span className="w-25 overflow-hidden">
                {(getWalletAddress() !== "" &&
                  notification?.scope === ScopeNotification.myWallet && (
                    <div className="overflow-hidden">
                      <b>My wallet: </b>
                      <div
                        className="flex cursor-pointer items-center transition"
                        onClick={() => copyToClipboard(getWalletAddress())}
                        title="Copy"
                      >
                        <div className="flex">
                          <p className="text-sm mr-2 ">
                            {shortURL(getWalletAddress())}
                          </p>
                          {copy && (
                            <Tooltip content="" isTooltipContentShow={false}>
                              <div className="rounded px-2 bg-gray-dark text-white">
                                Copied
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  )) ||
                  (notification?.scope === ScopeNotification.unique &&
                    NotificationType?.NftMonitor ===
                      notification?.notificationTypeId?.slice(-4) && (
                      <div className="overflow-hidden">
                        <b>Name: </b>
                        <div className="text-sm">{showMonitor()}</div>
                        <img src={""} alt={""} />
                      </div>
                    )) ||
                  (NotificationType?.ProfileChanges ===
                    notification?.notificationTypeId?.slice(-4) &&
                    notification.scope === ScopeNotification.unique && (
                      <div className="overflow-hidden">
                        <b>Validator Address: </b>
                        <div
                          className="cursor-pointer flex items-center transition"
                          onClick={() =>
                            copyToClipboard(notification?.validatorAddress?.[0])
                          }
                          title="Copy"
                        >
                          <div className="flex">
                            <p className="text-sm  mr-2">
                              {shortURL(notification?.validatorAddress?.[0]) ||
                                ""}
                            </p>
                            {copy && (
                              <Tooltip content="" isTooltipContentShow={false}>
                                <div className="rounded px-2 bg-gray-dark text-white">
                                  Copied
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    )) ||
                  (notification.notificationTypeId?.slice(-4) ===
                    NotificationType.WalletWatcher && (
                    <div className="overflow-hidden">
                      <b>Wallet: </b>
                      <div
                        className="cursor-pointer flex items-center transition"
                        onClick={() =>
                          copyToClipboard(notification?.walletAddress)
                        }
                        title="Copy"
                      >
                        <div className="flex">
                          <p className="text-sm mr-2 ">
                            {shortURL(notification?.walletAddress)}
                          </p>
                          {copy && (
                            <Tooltip content="" isTooltipContentShow={false}>
                              <div className="rounded px-2 bg-gray-dark text-white">
                                Copied
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </span>
            )}

            {/*  {notification.notificationTypeId === NotificationType.WalletWatcher && (
              <div className="w-full h-4 bg-gray-extralight50 rounded-full mt-3 relative">
                <span className="absolute -top-7 -right-1 block w-5 h-5 leading-5 rounded-full text-center text-xs range-line">
                  80
                </span>
                {notification.alertConfiguration &&
                  notification.alertConfiguration.params?.value != undefined &&
                  notification.alertConfiguration.params?.value > 0 && (
                    <div
                      className="ballon h-full text-center text-xs bg-gold50 rounded-full absolute"
                      style={{
                        width: `${
                          notification.alertConfiguration.params?.value * 1.25
                        }%`,
                      }}
                    >
                      <span className="absolute -top-7 -right-1 block bg-gold50 w-5 h-5 leading-5 rounded-full text-center text-xs">
                        {notification.alertConfiguration?.params?.value}
                      </span>
                    </div>
                  )}
                <div
                  className="ballon h-full text-center text-xs bg-gold rounded-full absolute"
                  style={{ width: `${35 * 1.25}%` }}
                >
                  <span className="absolute -top-7 -right-1 block bg-gold w-5 h-5 leading-5 rounded-full text-center text-xs">
                    35
                  </span>
                </div>
              </div>
            )} */}
            {/*  {notification?.notificationTypeId ===
              NotificationType?.NftMonitor && (
              <div className="mb-6">
                <img
                  className="h-16 w-16"
                  src="/images/partners/hero.jpeg"
                  alt="Hero NFT"
                />
              </div>
            )} */}

            {notification?.notificationTypeId ===
              NotificationType?.PriceAlert && (
              <div className="w-full h-full bg-gray-extralight20 text-center text-xs rounded-8px mt-2 py-0.5">
                <div className="flex items-center justify-center">
                  {notification?.pair && (
                    <>
                      <img
                        src={"/images/coin/" + notification?.pair + "1.svg"}
                        alt={notification?.pair[0]}
                        className="w-6 h-6"
                      />
                      <div className="ml-2 mr-2">
                        {t(`contract_pairs.${notification?.pair}`, {
                          ns: "enumerations",
                        })}
                      </div>
                      <img
                        src={"/images/coin/" + notification?.pair + "2.png"}
                        alt={notification?.pair[0]}
                        className="w-6 h-6"
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="absolute bottom-5 py-4 h-16  z-10">
            <div className="flex flex-col items-start">
              <span className="text-sm font-bold py-1 pb-3">
                {t("social_channels", { ns: "common" })}
              </span>
              <div className="text-xl flex flex-row gap-3">
                {notification?.discordId.length === 0 &&
                  notification?.telegramId.length === 0 &&
                  notification?.twitterId.length === 0 && (
                    <p className="text-sm">
                      {t("no_social_alerts_set", "common")}{" "}
                    </p>
                  )}
                {notification?.discordId.length > 0 && (
                  <FontAwesomeIcon className="text-discord" icon={faDiscord} />
                )}
                {notification?.telegramId.length > 0 && (
                  <FontAwesomeIcon
                    className="text-telegram"
                    icon={faTelegram}
                  />
                )}
                {notification?.twitterId.length > 0 && (
                  <FontAwesomeIcon className="text-twitter" icon={faTwitter} />
                )}
              </div>
            </div>
          </div>
          <div className="absolute opacity-90 top-0 left-0 h-full blur w-full rounded-2xl"></div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;

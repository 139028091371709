import { Balloon, BalloonSize, BalloonType } from "components/Balloon";
import { useContextUser } from "contexts/user";
import {
  CollectInfo,
  nftMonitor,
  Token,
  MonitorOptions,
  Nft,
  UserNotification,
} from "models/Interfaces";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigurationParams } from "../../../models/Notification";
import {
  BlockchainType,
  NotificationType,
  ScopeNotification,
  SocialChannel,
} from "models/Enums";
import {
  getCollections,
  getDataCollectionBlockchain,
  getNftByCollection,
  getNftListingLocalStorage,
  isValidWallet,
  loadingNFTUserByCollection,
} from "models/Utils/terra";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { capitalizeName, countWallets, shortURL } from "../Utils/shared";
import SocialChannels from "../Utils/SocialChannels";
import { useContextBlockchainData } from "contexts/blockchain-data";
import Button, { ButtonSize } from "components/Button";

export const NFTMonitor = ({
  disabledBtn,
  setValues,
  selectedValues,
  socialChannelsData,
  setSocialChannels,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  selectedValues: ConfigurationParams;
  socialChannelsData: SocialChannel[];
  setSocialChannels: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  editView?: boolean;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const [scope, setScope] = useState<string | "">("");
  const [nftWalletAddress, setNftWalletAddress] = useState<string | "">("");
  const [collectionAddress, setCollectionAddress] = useState<string | "">("");
  const [tokensCollection, setTokensCollection] = useState<Token[] | null>(
    null
  );
  const [collectionList, setCollectionList] = useState<CollectInfo[] | null>(
    null
  );
  const [inputCollectionAddress, setInputCollectionAddress] = useState<
    string | ""
  >("");
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [listNFT, setListNFT] = useState<{} | null>(null);
  const [nftMonitor, setNftMonitor] = useState<nftMonitor>({
    zc6_0001: false,
    zc6_0002: false,
    zc6_0003: false,
    zc6_0004: false,
    zc6_0005: false,
    zc6_0006: false,
    zc6_0007: false,
    zc6_0008: false,
    zc6_0009: false,
  });
  const { userData } = useContextUser();
  const { selectedNotificationBlockchain } = useNewNotificationFlow();
  const { monitorOptions } = useContextBlockchainData();

  const blockChainSelected =
    selectedNotificationBlockchain === BlockchainType.TerraClassic
      ? "classic"
      : "mainnet";

  useEffect(() => {
    selectedNotificationBlockchain && loadingCollectionList();
    notification?.notificationId !== undefined && isEdit();
  }, []);

  const isEdit = () => {
    if (notification?.notificationId !== null) {
      setScope(notification?.scope as ScopeNotification);

      const list = {};
      notification?.monitorOptions.forEach((k) => {
        Object.assign(list, { [k]: true });
      });
      setNftMonitor({ ...nftMonitor, ...list });
      setShowOptions(true);

      if (notification?.scope === ScopeNotification.myWallet) {
        setNftWalletAddress(notification?.walletAddress);
      } else {
        const collectionAddress: any =
          notification?.nft && Object.values(notification?.nft)?.[0];
        setCollectionAddress(collectionAddress);
        setInputCollectionAddress(collectionAddress);
      }
    }
  };

  const loadingCollectionList = () =>
    (async () =>
      await getCollections(
        selectedNotificationBlockchain as BlockchainType
      ))().then((data) => {
      setCollectionList(data);
    });

  // search collections
  const foundCollection = (word?: string) => {
    const result =
      collectionList &&
      Object.values(collectionList)?.filter((data) => {
        return (
          (word &&
            data?.contract?.toLowerCase().includes(word?.toLowerCase())) ||
          (word && data?.name?.toLowerCase().includes(word?.toLowerCase()))
        );
      });

    return result && result?.length > 0 ? result?.sort() : undefined;
  };

  // search NFT
  const foundToken = () => {
    return nftWalletAddress
      ? tokensCollection?.filter((data: Token) => {
          return (
            data?.name
              ?.toLowerCase()
              .includes(nftWalletAddress?.toLowerCase()) ||
            data?.tokenId
              ?.toLowerCase()
              .includes(nftWalletAddress?.toLowerCase())
          );
        })
      : tokensCollection;
  };

  useEffect(() => {
    if (scope === ScopeNotification.unique) {
      nftWalletAddress !== "" && foundToken();
    } else {
      if (isValidWallet(nftWalletAddress)) {
        collectionList !== null && handleRefreshTokens();
      }
    }

    nftWalletAddress != "" && !foundToken()?.length && setShowOptions(true);
  }, [nftWalletAddress, collectionList]);

  const resetNFTMonitor = () => {
    setNftMonitor({
      zc6_0001: false,
      zc6_0002: false,
      zc6_0003: false,
      zc6_0004: false,
      zc6_0005: false,
      zc6_0006: false,
      zc6_0007: false,
      zc6_0008: false,
      zc6_0009: false,
    });
  };

  const resetFields = (): void => {
    resetNFTMonitor();
    setNftWalletAddress("");
    setCollectionAddress("");
    setInputCollectionAddress("");
    disabledBtn && disabledBtn(true);
  };

  const handleScope = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    resetFields();
    setScope(e.target.value);
    setShowOptions(false);
    if (e?.target?.value === ScopeNotification.myWallet) {
      // set myWallet and user has more than one wallet
      countWallets(userData) === 1 &&
        setNftWalletAddress(userData?.wallets[0]?.walletAddress || "");
      setShowOptions(true);
    }
  };
  // if myWallet and user has more than one wallet **
  const checkAndSetWallet = (
    event?: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    event?.target.value === "" && resetFields();
    event && setNftWalletAddress(event?.target?.value || "");
  };

  const checkCollection = (
    contract: string,
    blockchain: BlockchainType = selectedNotificationBlockchain as BlockchainType
  ) => {
    const res = (async () =>
      await getDataCollectionBlockchain(contract, blockchain))();
    res.then((data) => {
      data?.address && setCollectionAddress(data?.address);
    });
  };

  const handleNFTCollection = async (
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputCollection = event?.target.value;
    setNftWalletAddress("");
    setInputCollectionAddress(inputCollection || "");
    if (inputCollection !== undefined) {
      inputCollection === "" && resetFields();
      isValidWallet(inputCollection) && checkCollection(inputCollection);
    }
  };

  const handleNFTAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetNFTMonitor();
    setNftWalletAddress(event?.target.value);
    // se não tokens settar o showOptions como true se não false p esconder
  };

  const handleClickCollection = async (contract: string) => {
    //the check collection function must be reviewed to work for all BC
    //checkCollection(contract);
    setCollectionAddress(contract)
    setTokensCollection(null);
    if (collectionAddress !== null) {
      const tokens = await getNftByCollection(contract, blockChainSelected);
      setTokensCollection(tokens);
    }
  };

  // checked collection is filled
  const hasCollectionNFT = (): boolean => {
    return Object.values(nftMonitor).includes(true);
  };

  const handleClickToken = (
    id: string | undefined,
    name: string | undefined
  ) => {
    setShowOptions(true);
    document.querySelector("#containerTokens")?.classList.add("hidden");
    name && setNftWalletAddress(name);
    if (id !== undefined) setValues({ nft: { tokenId: id } });
  };
  /* Merging these two functions in component later */
  const showHTMLCollections = () => {
    const collectionToShow =
      inputCollectionAddress !== ""
        ? foundCollection(inputCollectionAddress)
        : collectionList;
    return (
      collectionToShow &&
      Object.values(collectionToShow)?.map((key: CollectInfo) => {
        const pageContract =
          selectedNotificationBlockchain === BlockchainType.Near
            ? `https://nearblocks.io/address/${key.contract}#transaction`
            : `https://terrasco.pe/${blockChainSelected}/address/${key.contract}`;
        return (
          <>
            <div
              key={key.contract}
              className="w-full px-2 sm:px-0 sm:w-4/12 overflow-hidden inline-block relative"
            >
              <div
                className={`${
                  collectionToShow?.length > 2 && "border-b"
                } flex py-4 cursor-pointer hover:bg-gray-extralight20 grow transition-colors ease-out`}
                onClick={() => {
                  setInputCollectionAddress(key?.contract);
                  handleClickCollection(key?.contract);
                }}
              >
                <div className="mx-2 ">
                  <img
                    alt={key["name"]}
                    className="p-1 shadow "
                    width="65"
                    height={"65"}
                    src={key["icon"]}
                  />
                </div>
                <div>
                  <div className="flex mt-1 ">
                    <small className="w-32 break-words overflow-hidden font-bold ">
                      {capitalizeName(key["name"])}
                    </small>
                    <div
                      onClick={() => {
                        setInputCollectionAddress(key?.contract);
                        handleClickCollection(key?.contract);
                      }}
                      className="mx-1 hover:opacity-75 focus:opacity-75 opacity-25 cursor-pointer"
                    ></div>
                  </div>
                  <span className="block text-xs flex text-blue-500 ml-2">
                    {shortURL(key["contract"])}
                    <span className="-ml-1 -mt-1 ">
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        width={20}
                        height={20}
                        transform="scale(0.47)"
                      >
                        <path
                          fill="#3b82f6b5"
                          d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </div>
              </div>

              <a
                href={pageContract}
                target="_blank"
                rel="noreferrer"
                className="absolute right-3 top-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width={15}
                  height={15}
                  className="inline"
                >
                  <path
                    fill="#3b82f6b5"
                    d="M256 64C256 46.33 270.3 32 288 32H415.1C415.1 32 415.1 32 415.1 32C420.3 32 424.5 32.86 428.2 34.43C431.1 35.98 435.5 38.27 438.6 41.3C438.6 41.35 438.6 41.4 438.7 41.44C444.9 47.66 447.1 55.78 448 63.9C448 63.94 448 63.97 448 64V192C448 209.7 433.7 224 416 224C398.3 224 384 209.7 384 192V141.3L214.6 310.6C202.1 323.1 181.9 323.1 169.4 310.6C156.9 298.1 156.9 277.9 169.4 265.4L338.7 96H288C270.3 96 256 81.67 256 64V64zM0 128C0 92.65 28.65 64 64 64H160C177.7 64 192 78.33 192 96C192 113.7 177.7 128 160 128H64V416H352V320C352 302.3 366.3 288 384 288C401.7 288 416 302.3 416 320V416C416 451.3 387.3 480 352 480H64C28.65 480 0 451.3 0 416V128z"
                  />
                </svg>
              </a>
            </div>
          </>
        );
      })
    );
  };

  const showHTMLTokens = () => {
    const tokensToShow = foundToken();
    return tokensToShow?.slice(0, 100).map((key: Token) => {
      return (
        <>
          <div
            key={key?.name}
            className="w-full px-2 sm:px-0 sm:w-4/12 overflow-hidden inline-block cursor-pointer relative"
          >
            <div
              onClick={() => handleClickToken(key?.tokenId, key?.name)}
              className={`${
                tokensToShow !== null && tokensToShow?.length > 2 && "border-b"
              } flex py-4 hover:bg-gray-extralight20 grow transition-colors ease-out`}
            >
              <div className="mx-2 ">
                <img
                  alt={key?.name}
                  className="p-1 shadow cursor-pointer"
                  width="65"
                  height={"65"}
                  src={key?.image}
                />
              </div>

              <div className="flex mt-1 ">
                <small className="w-32 break-words overflow-hidden font-bold ">
                  <span>{key?.name && capitalizeName(key?.name)}</span>
                </small>
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  const handleOptionsMonitor = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event?.target?.name;

    const newCollection = Object.entries(nftMonitor)
      .map(([k, v]) => {
        if (k === name) {
          v = event?.target?.checked;
        }
        return [k, v];
      })
      .reduce((result, item) => {
        return { ...result, [item[0]]: item[1] };
      }, {} as nftMonitor);

    setNftMonitor(newCollection);
  };

  useEffect(() => {
    allowButton();
  }, [nftMonitor]);

  const allowButton = () => {
    // restart objs next step
    disabledBtn && disabledBtn(true);

    let monitorOptions: string[] = [];

    Object.entries(nftMonitor).map((o) => o[1] && monitorOptions.push(o[0]));

    const sendObj = {
      monitorOptions,
      scope: scope,
      walletAddress:
        ScopeNotification.myWallet === scope ? nftWalletAddress : null,
      nft: [
        {
          collection: "",
          name: "",
          tokenId: "",
        },
      ],
    };

    if (ScopeNotification.myWallet === scope) {
      const nftListing =
        getNftListingLocalStorage() !== null &&
        JSON.parse(getNftListingLocalStorage() || "{}");

      const listingToApi: Nft[] = [];

      for (const [key, value] of Object.entries(nftListing)) {
        const nftNamesArr = Object.values(nftListing[key]);
        nftNamesArr.forEach(
          (value: string | any, index: number, array: unknown[]) => {
            listingToApi.push({
              collection: key,
              tokenId: value,
            });
          }
        );
      }
      Object.assign(sendObj, { nft: listingToApi });
    }
    if (ScopeNotification.unique === scope) {
      Object.assign(sendObj, {
        walletAddress: "",
        nft: {
          collection: collectionAddress,
          name: nftWalletAddress,
          ...selectedValues.nft,
        },
      });
    }
    if (hasCollectionNFT()) {
      setValues({
        ...selectedValues,
        ...sendObj,
      });

      disabledBtn && disabledBtn(false);
    }
  };

  const handleRefreshTokens = () => {
    const result = loadingNFTUserByCollection(
      selectedNotificationBlockchain as BlockchainType,
      nftWalletAddress,
      collectionList as CollectInfo[]
    );

    buildHTMLListingNft(result);

    setTimeout(() => {
      setListNFT(true);
    }, 3000);
  };

  const buildHTMLListingNft = (result?: {}) => {
    const nftListing: Array<[{}]> =
      result !== null &&
      JSON.parse(localStorage.getItem("user_nft_listing") || "{}");
    const NftData: Array<[key: string, value: number]> = [];

    for (const [contract, listNft] of Object.entries(nftListing)) {
      NftData.push([contract, Object.values(listNft)?.length]);
    }
    NftData.concat(NftData.sort());

    return NftData.length > 0 ? (
      NftData.map(([contract, nftAmount]) => {
        const contractName = returnNameCollection(contract);
        const contractIcon = returnImgContract(contract);

        return (
          <>
            <tr className="border border-gold20 px-2 ">
              <td className="border border-gold20 py-3">
                <div className="flex">
                  <img
                    className="mx-4"
                    width={"30"}
                    src={contractIcon || returnImgBlockchain()[0]}
                    alt={contractName || ""}
                  />
                  {contractName}
                </div>
              </td>
              <td className="border border-gold20 py-3">{nftAmount}</td>
            </tr>
          </>
        );
      })?.sort()
    ) : (
      <>
        <tr className="border border-gold20 px-2 ">
          <td className="border border-gold20 py-3">
            No collection on this wallet to show
          </td>
        </tr>
      </>
    );
  };

  const returnImgBlockchain = () => {
    return selectedNotificationBlockchain === BlockchainType.TerraClassic
      ? ["/images/coin/Lunc.svg", "LUNC"]
      : ["/images/coin/Luna.svg", "LUNA"];
  };

  const returnImgContract = (contract: string): string => {
    const contractData =
      collectionList &&
      Object.entries(collectionList)?.filter(([contractNumber, data]) => {
        return contractNumber === contract && data?.icon !== undefined && data;
      })[0];

    let newURL = "";

    if (
      contractData?.[1].icon?.indexOf("/ipfs/") !== undefined &&
      contractData?.[1].icon?.indexOf("/ipfs/") > -1
    )
      newURL = `https://cloudflare-ipfs.com/${
        new URL(`${contractData?.[1].icon}`)?.pathname
      }`;

    newURL = (newURL === "" && contractData?.[1].icon) || newURL;

    return newURL;
  };

  const returnNameCollection = (contract: string) => {
    const contractData =
      collectionList &&
      Object.entries(collectionList)?.filter(([contractNumber, data]) => {
        return contractNumber === contract && data?.name !== undefined && data;
      })[0];

    return (
      contractData?.[1] &&
      contractData?.[1].name !== undefined &&
      contractData?.[1].name
    );
  };

  return (
    <>
      <div className="w-full">
        <div
          className={`pb-4 border-b border-black-line w-full ${
            notification?.notificationId !== undefined && "hidden"
          }`}
        >
          <h3>{t("notifications.nft_monitor", { ns: "common" })}</h3>
        </div>
        <div className="relative block w-full mt-2">
          <div className="sm:my-6">
            <div className="px-2">
              {notification?.notificationId === undefined &&
                scope &&
                scope === ScopeNotification.myWallet && (
                  <Button
                    className={"ml-auto "}
                    onClick={() => {
                      setListNFT(false);
                      handleRefreshTokens();
                    }}
                    size={ButtonSize.mediumShorter}
                  >
                    NFT refresh
                  </Button>
                )}
            </div>

            <div>
              <p className="hidden sm:block">
                {t("notifications.scope", { ns: "common" })}
              </p>
            </div>
            <select
              value={scope}
              onChange={handleScope}
              disabled={notification?.notificationId !== undefined}
              className="focus:border-gray-extralight60 focus:ring-0 focus:outline-none "
            >
              <option value="">
                {t(`notifications.select_scope`, { ns: "common" })}
              </option>
              {Object.values(ScopeNotification).map((scope: string) => (
                <option value={scope} key={Math.random() * 0.5}>
                  {t(`scope_notifications.nft.${scope}`, {
                    ns: "enumerations",
                  })}
                </option>
              ))}
            </select>
          </div>

          {(notification?.notificationId !== undefined &&
            notification?.scope === ScopeNotification.myWallet) ||
            (scope === ScopeNotification.myWallet && (
              <div className="mt-6">
                <>
                  <p>
                    {t("notifications.choose_wallet", {
                      ns: "common",
                    })}
                  </p>
                  <select
                    disabled={countWallets(userData) === 1}
                    value={nftWalletAddress}
                    className={`focus:border-0 myWallet focus:ring-0 focus:outline-none ${
                      countWallets(userData) === 1 && " bg-gray-extralight20"
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      checkAndSetWallet(e);
                    }}
                  >
                    {countWallets(userData) > 1 && (
                      <option value="">
                        {t(`notifications.select_wallet`, { ns: "common" })}
                      </option>
                    )}
                    {userData?.wallets.map((w) => (
                      <option value={w.walletAddress}>
                        {w.walletAlias || w.walletAddress}
                      </option>
                    ))}
                  </select>
                  <div
                    className={`my-8 ${
                      notification?.notificationId !== undefined && "hidden"
                    }`}
                  >
                    {listNFT ? (
                      <div
                        className={`overflow-hidden transition min-h-10 max-h-36 mt-2 customScrollbar overflow-y-auto border border-gold20 shadow-xl`}
                      >
                        <table className="table-fixed text-center w-full">
                          <tbody>
                            <tr className=" border border-gold20">
                              <td
                                className={`font-bold text-gold border text-bold border-gold20 py-2 w-5/6`}
                              >
                                Collection Number
                              </td>
                              <td className="font-bold text-gold border-gold20 py-4 w-1/4">
                                <div className="flex justify-center">
                                  <span>Amount NFT</span>
                                  <img
                                    width="24"
                                    className="ml-3"
                                    src={returnImgBlockchain()?.[0]}
                                    alt={returnImgBlockchain()?.[1]}
                                  />
                                </div>
                              </td>
                            </tr>
                            {buildHTMLListingNft()}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <>
                        <div className="text-center pt-8">
                          <small className="relative inline-block w-24 text-gold ">
                            {t("alert_configuration_settings.loading", {
                              ns: "common",
                            })}

                            <button
                              type="button"
                              className="btn-wrapper bg-indigo-500 block"
                              disabled
                            >
                              <div className="loading-ring gray-dark text-gold"></div>
                            </button>
                          </small>
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            ))}

          {(notification?.notificationId !== undefined &&
            notification?.scope === ScopeNotification.unique) ||
            (scope === ScopeNotification.unique && (
              <div className="mt-6">
                <>
                  <p>
                    {t("notifications.choose_collection", {
                      ns: "common",
                    })}
                  </p>
                  <div>
                    <div>
                      <input
                        onChange={(e) => {
                          handleNFTCollection(e);
                        }}
                        type="text"
                        value={inputCollectionAddress}
                        placeholder={`Collection Name / Address`}
                        className={`focus:ring-0 focus:opacity-100 focus:border-gray-extralight90medium focus:outline-none text-sm rounded w-full py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-gray-medium ${
                          nftWalletAddress !== "" && "opacity-50"
                        }`}
                        disabled={nftWalletAddress !== "" && true}
                        required={true}
                      />

                      {inputCollectionAddress !== "" &&
                        !foundCollection(inputCollectionAddress) && (
                          <small className={`px-1 text-red-500 `}>
                            {t("notifications.invalid_collection", {
                              ns: "common",
                            })}
                          </small>
                        )}
                      {isValidWallet(collectionAddress) && (
                        <div className="my-4">
                          <input
                            onChange={(e) => {
                              handleNFTAddress(e);
                            }}
                            type={`text`}
                            value={nftWalletAddress}
                            placeholder={`${
                              !foundToken()?.length
                                ? "Token ID"
                                : "NFT Name / Address"
                            }`}
                            className={`focus:ring-0 focus:opacity-100 ${
                              hasCollectionNFT() && "opacity-50"
                            } focus:border-gray-extralight90medium focus:outline-none text-sm rounded w-full py-2 2xl:h-10 h-9 px-3 border-gray-extralight90medium text-gray-medium `}
                            required={true}
                            disabled={hasCollectionNFT()}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className={`${
                        isValidWallet(inputCollectionAddress) && "hidden h-0"
                      }`}
                    >
                      <div
                        className={`
                          "overflow-hidden transition transition-height duration-500 ease-in-out 2xl:max-h-80 xl:max-h-60 max-h-36 transitionHeightSmooth mt-2 customScrollbar overflow-y-auto shadow-inner  border-gold20 shadow-xl"
                        } `}
                      >
                        {showHTMLCollections()}
                      </div>
                    </div>
                  </div>

                  {collectionAddress &&
                    (!foundCollection(collectionAddress) ? (
                      <small className={`py-2`}>
                        {t("alert_configuration_settings.nft_not_indexed", {
                          ns: "common",
                        })}
                      </small>
                    ) : (
                      <div className="">
                        {foundToken()?.length ? (
                          <div id="containerTokens">
                            <div
                              className={`
                         "overflow-hidden transition transition-height duration-500 ease-in-out 2xl:max-h-72 xl:max-h-60 max-h-36 transitionHeightSmooth mt-2 customScrollbar overflow-y-auto shadow-inner border-gold20 shadow-xl"
                       } `}
                            >
                              {showHTMLTokens()}
                            </div>
                          </div>
                        ) : (
                          <small className={`py-2`}>
                            {t("alert_configuration_settings.nft_not_found", {
                              ns: "common",
                            })}
                          </small>
                        )}
                      </div>
                    ))}
                </>
              </div>
            ))}

          {/* send this code for to new component*/}
          {showOptions && (
            <div className="my-6 w-full">
              <div className="p-4 shadow-sm bg-gold10 rounded">
                <div className={`nft-monitor`}>
                  <div className={`flex items-center`}>
                    <p>
                      {t("notifications.monitor_options", {
                        ns: "common",
                      })}
                    </p>
                  </div>
                  <div
                    className={`flex flex-wrap items-center px-0 py-2 rounded sm:overflow-hidden sm:max-h-full max-h-28 overflow-scroll transition customScrollbar border sm:border-0 border-gold20 sm:shadow-none shadow-xl ${
                      notification?.notificationId === undefined &&
                      !nftWalletAddress &&
                      "opacity-50"
                    }`}
                  >
                    {monitorOptions &&
                      monitorOptions.map((el: MonitorOptions) => {
                        return Object.entries(nftMonitor).map(([v, k]) => {
                          if (el?.option_id === v) {
                            return (
                              <>
                                <div className="sm:w-4/12 px-1 sm:py-1 max-h-12 sm:h-auto w-full">
                                  <label
                                    htmlFor={`${el.option_desc}`}
                                    className="flex items-center cursor-pointer"
                                  >
                                    <input
                                      type="checkbox"
                                      name={el.option_id}
                                      id={el.option_desc}
                                      className={`sm:mr-2 mr-1 checked:border-0 ${
                                        notification?.notificationId ===
                                          undefined &&
                                        !nftWalletAddress &&
                                        " bg-gray-extralight80 "
                                      } text-gold border-gold cursor-pointer transition-colors focus:ring-0 focus:outline-none`}
                                      onChange={handleOptionsMonitor}
                                      disabled={
                                        notification?.notificationId ===
                                          undefined && !nftWalletAddress
                                      }
                                      checked={k}
                                    />
                                    <span className="text-sm ">
                                      {t(
                                        `nft_monitor_options.${el?.option_id}`,
                                        {
                                          ns: "enumerations",
                                        }
                                      )}{" "}
                                    </span>
                                    {Object.entries(el?.allMarketplaces)?.map(
                                      ([blockchain, state]) => {
                                        selectedNotificationBlockchain ===
                                          blockchain &&
                                          state && (
                                            <div>
                                              <span className="relative -left-4">
                                                <Balloon
                                                  disabled={true}
                                                  type={BalloonType.info}
                                                  id="connect-info"
                                                  size={BalloonSize.smaller}
                                                  noEffectHover={true}
                                                >
                                                  <span>!</span>
                                                </Balloon>
                                              </span>
                                            </div>
                                          );
                                      }
                                    )}
                                  </label>
                                </div>
                              </>
                            );
                          } else {
                            return;
                          }
                        });
                      })}
                  </div>
                  <small className="-ml-1.5 mt-2 flex items-center">
                    <span>
                      <Balloon
                        disabled={true}
                        type={BalloonType.info}
                        id="connect-info"
                        noFlex={true}
                        noEffectHover={true}
                      >
                        <span>!</span>
                      </Balloon>
                    </span>
                    <span>
                      {t("notifications.monitor_options_info", {
                        ns: "common",
                      })}
                      {t("notifications.see_compatibilities_table", {
                        ns: "common",
                      })}
                      <a
                        href="https://hermesprotocol.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-gold"
                      >
                        {t("notifications.here", {
                          ns: "common",
                        })}
                      </a>
                    </span>
                  </small>
                </div>
              </div>

              <SocialChannels
                socialChannelsData={socialChannelsData}
                setSocialChannels={setSocialChannels}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

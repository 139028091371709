import Loader, { LoaderType } from "components/Loader";
import {
  Blockchain,
  Project,
  Category,
  Collection,
  NotificationTypes,
  Scope,
  MonitorOptions,
  PriceType,
} from "models/Interfaces";
import React, { createContext, useContext, useEffect, useState } from "react";
import { terra, terraClassic } from "shared";

/* Interface creation */
export interface BlockchainDataContext {
  blockchains: Blockchain[] | null;
  projects: Project[] | null;
  categories: Category[] | null;
  collections: Collection[] | null;
  types: NotificationTypes[] | null;
  scopes: Scope[] | null;
  monitorOptions: MonitorOptions[] | null;
  priceTypes: PriceType[] | null;
}

/* Context */
const BlockchainDataContext = createContext<BlockchainDataContext>({
  blockchains: null,
  projects: null,
  categories: null,
  collections: null,
  types: null,
  scopes: null,
  monitorOptions: null,
  priceTypes: null,
});

/* Using Context*/
export const useContextBlockchainData = () => useContext(BlockchainDataContext);

/* Provider fn */
export const BlockchainDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [blockchains, setBlockchains] = useState<Blockchain[] | null>(null);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [collections, setCollections] = useState<Collection[] | null>(null);
  const [types, setTypes] = useState<NotificationTypes[] | null>(null);
  const [scopes, setScopes] = useState<Scope[] | null>(null);

  const [monitorOptions, setMonitorOptions] = useState<MonitorOptions[] | null>(
    null
  );

  const [priceTypes, setPriceTypes] = useState<PriceType[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getBlockchainData = async () => {
    setIsLoading(true);
    const connection = await fetch(
      `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/notifications/`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    const dataFromApi = await connection.json();
    setBlockchains(dataFromApi.data.chains);
    setProjects(dataFromApi.data.projects);
    setCollections(dataFromApi.data.collections);
    setCategories(dataFromApi.data.categories);
    setTypes(dataFromApi.data.types);
    setScopes(dataFromApi.data.scopes);
    setMonitorOptions(dataFromApi.data.monitorOptions);
    setPriceTypes(dataFromApi.data.priceTypes);
  };

  useEffect(() => {
    getBlockchainData();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [blockchains]);

  const blockchainData = {
    blockchains,
    projects,
    categories,
    collections,
    types,
    scopes,
    monitorOptions,
    priceTypes,
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}

      <BlockchainDataContext.Provider value={blockchainData}>
        {children}
      </BlockchainDataContext.Provider>
    </>
  );
};

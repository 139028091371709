import { useEffect, useLayoutEffect, useRef, useState } from "react";
import RightSection from "../components/RightSection";
import "./../style.css";
import "../i18n.js";
import { NotificationsProvider } from "contexts/notifications";
import { NewNotificationFlowContextProvider } from "contexts/new-notification-flow";
import { Notifications } from "../components/Notifications";
import BottomMenu from "../components/BottomMenu";
import { EditNotificationFlowContextProvider } from "contexts/edit-notification-flow";
import NotificationEditFlow from "../components/NotificationEditFlow";
import PopUp, { PopupSize } from "components/Popup";
import classNames from "classnames";
import vector from "../assets/images/vector.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SocialAccounts } from "components/Modals";
import { useContextUser } from "contexts/user";
import { BlockchainDataProvider } from "contexts/blockchain-data";
import Loader, { LoaderType } from "components/Loader";
import NotificationFlow from "components/Notifications/Flow/New";
import { Navbar } from "components/Navbar";
import { instanceOfUserData } from "utils/instanceOf";
import { SocialChannel } from "models/Enums";


export const NotificationsPage: React.FC<{
  error: string | null;
}> = ({ error }) => {
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [showLoginSocials, setShowLoginSocials] = useState<null | boolean>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);

  const { t } = useTranslation(["common", "enumerations"]);
  const { setUser, userData, isLoggedIn } = useContextUser();

  const handleSkip = () => {
    sessionStorage.setItem("socialsLogin", "false");
    setIsOpenModal(false);
  };

  const handleSkipForever = async () => {
    setIsLoading(true);
    try {
      if (userData) {
        const connection = await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/user/${userData.userId}`,
          {
            method: "PATCH",
            body: JSON.stringify({ noSocialsPopup: true }),
            credentials: "include",
          }
        );

        const dataFromApi = await connection.json();
        if (
          typeof dataFromApi.data === "object" &&
          instanceOfUserData(dataFromApi.data)
        ) {
          setUser(dataFromApi.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    setIsOpenModal(false);
  };

  useEffect(() => {
    const hasSkip = sessionStorage.getItem("socialsLogin");
    if (
      userData?.socials !== null &&
      userData?.socials.length === Object.keys(SocialChannel).length
    ) {
      setShowLoginSocials(false);
    } else if (hasSkip === "false") {
      setShowLoginSocials(false);
    } else if (userData?.noSocialsPopup) {
      setShowLoginSocials(false);
    } else {
      setShowLoginSocials(true);
    }
  }, [userData]);

  useEffect(() => {
    setNavbarWidth(document.getElementById("sideMenu")?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}

      <BlockchainDataProvider>
        <NotificationsProvider>
          <NewNotificationFlowContextProvider>
            <EditNotificationFlowContextProvider>
              <Navbar
                setIsCondensed={setIsCondensed}
                isCondensed={isCondensed}
              />
              <RightSection isCondensed={isCondensed}>
                <Notifications />
              </RightSection>
              <BottomMenu />
              <NotificationFlow />
              <NotificationEditFlow />
            </EditNotificationFlowContextProvider>
          </NewNotificationFlowContextProvider>
        </NotificationsProvider>
      </BlockchainDataProvider>
      {isOpenModal && showLoginSocials && (
        <PopUp size={PopupSize.large}>
          {error ? (
            <>
              <img src={vector} alt="exclamation" className="inline " />
              <div className="text-center py-4">
                <h1 className="font-bold text-2xl pt-4 w-full">
                  {t("common:sorry", { ns: "common" })}
                </h1>
                <h2 className="font-normal text-2xl mx-14 pb-3 pt-4">
                  {error.split("\n").map((c) => (
                    <p> {c} </p>
                  ))}
                </h2>
                <Link
                  to={"/dashboard"}
                  onClick={() => {
                    window.location.reload();
                    setIsOpenModal(true);
                  }}
                  className="text-center cursor-pointer underline font-normal w-1/6 text-xl	text-gold"
                >
                  {t("retry", { ns: "common" })}
                </Link>
              </div>
            </>
          ) : (
            <>
              <h1 className="font-normal text-2xl md:text-3xl mb-3 text-black text-center">
                {t("last_step", { ns: "common" })}
              </h1>
              <h2 className="text-center font-normal text-lg md:text-xl text-black ">
                {t("social_accounts", { ns: "common" })}
              </h2>
              <div>
                <SocialAccounts />
              </div>
            </>
          )}
          <div
            className={classNames(
              "flex justify-between w-full pr-2",
              {
                "justify-between": !error,
              },
              {
                "justify-end": error,
              }
            )}
          >
            {!error ? (
              <>
                <Link
                  to="/notifications"
                  onClick={() => handleSkipForever()}
                  className="text-center cursor-pointer underline font-normal text-base text-gray-extralight80"
                >
                  {t("action_skip_forever", { ns: "common" })}
                </Link>
                <Link
                  to="/notifications"
                  onClick={() => handleSkip()}
                  className="text-center cursor-pointer underline font-normal text-base	w-1/6"
                >
                  {t("action_skip", { ns: "common" })}
                </Link>
              </>
            ) : (
              <Link
                to="/notifications"
                onClick={() => setIsOpenModal(false)}
                className="text-center cursor-pointer underline font-normal w-1/6 text-xl"
              >
                {t("exit", { ns: "common" })}
              </Link>
            )}
          </div>
        </PopUp>
      )}
    </>
  );
};

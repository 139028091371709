import { UserNotification } from "models/Interfaces";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export const GovernancePoll = ({
  disabledBtn,
  notification,
}: {
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["enumerations"]);

  disabledBtn && disabledBtn(false);

  return (
    <>
      {!notification && (
        <div className="pb-4 border-b border-black-line w-full ">
          <h3>{t("notifications.governance_poll", { ns: "common" })}</h3>
        </div>
      )}
    </>
  );
};

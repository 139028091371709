import Account from "components/Account";
import Logo from "components/Logo";
import SideMenu, { ModeType } from "components/SideMenu";
import SideMenuItem from "components/SideMenuItem";
import { Dispatch, SetStateAction, useState } from "react";
import { Tooltip } from "./Tooltip";

export const Navbar: React.FC<{
  setIsCondensed: Dispatch<SetStateAction<any>>;
  isCondensed: boolean;
}> = ({ setIsCondensed, isCondensed }) => {
  return (
    <div className="fixed">
      <SideMenu mode={isCondensed ? ModeType.condensed : ModeType.normal}>
        <Logo
          onClick={() => setIsCondensed(!isCondensed)}
          condensed={isCondensed}
        />
        <Tooltip content={<span>Coming soon!</span>}>
          <SideMenuItem
            icon="dashboard"
            title="Dashboard"
            url="/dashboard"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <SideMenuItem
          icon="notifications"
          title="Notifications"
          url="/notifications"
          condensed={isCondensed}
        />
        <Tooltip content={<span>Coming soon!</span>}>
          <SideMenuItem
            icon="history"
            title="History"
            url="/history"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <Tooltip content={<span>Coming soon!</span>}>
          <SideMenuItem
            icon="messages"
            title="Messages"
            url="/messages"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <Tooltip content={<span>Coming soon!</span>}>
          <SideMenuItem
            icon="trade"
            title="Trade"
            url="/trade"
            condensed={isCondensed}
            disabled={true}
          />
        </Tooltip>
        <SideMenuItem
          icon="settings"
          title="Settings"
          url="/settings"
          condensed={isCondensed}
        />
        {/* <UpgradeButton condensed={isCondensed} /> */}
        <div className="flex-grow justify-self-end flex items-end">
          <Account condensed={isCondensed} />
        </div>
      </SideMenu>
    </div>
  );
};

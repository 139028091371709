import React, { useEffect, useState } from "react";
import Button, { ButtonType, ButtonSize } from "components/Button";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import twitter from "../../assets/images/twitter.svg";
import discord from "../../assets/images/discord.svg";
import telegram from "../../assets/images/telegram.svg";
import { getSocialAuthorizeUrl } from "utils/handleLogin";
import { useContextUser } from "contexts/user";
import { connectNear } from "utils/near.connection";

export enum TooltipContent {
  connect = "connect-info",
  verify = "verify-info",
}

const LoginSectionButtons: React.FC<{
  onConnectWalletClick: React.MouseEventHandler;
  walletStatus: string;
}> = ({ onConnectWalletClick, walletStatus }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [width, setWidth] = useState(window.innerWidth);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  return (
    <div className="flex flex-row flex-nowrap	justify-center md:mt-8">
      <div className="flex flex-col relative">
        <div className="h-16 my-2 flex items-center justify-center mb-2 md:mb-6">
          <Button
            onClick={() => connectNear("login")}
            type={ButtonType.secondary}
            size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
            fullWidth
          >
            <img
              src="https://assets.hermesprotocol.io/img/projects/near.png"
              className={classNames("inline")}
              alt="Terra Wallet"
              width={20}
            />
            <span className="pl-6">
              {t(`wallet_action.connect`, { ns: "common" })}
            </span>
          </Button>
        </div>
        <>
          <div className="text-center border-b text-gold border-gold leading-0.5">
            <span className="px-2 bg-white">Or</span>
          </div>
          <div className="h-16 mt-2 md:mt-6 flex items-center justify-center">
            <a href={getSocialAuthorizeUrl("discord")} className="w-full block">
              <Button
                onClick={() => localStorage.setItem("origin", "off")}
                type={ButtonType.secondary}
                size={
                  width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                }
                fullWidth
              >
                <img
                  src={discord}
                  className={classNames("inline w-6")}
                  alt="Discord icon"
                />
                <span className="pl-3 md:pl-6">Log in with Discord</span>
              </Button>
            </a>
          </div>
          {/* <div className="h-12 flex items-center justify-center">
            <a href={getSocialAuthorizeUrl("twitter")} className="w-full block">
              <Button
                onClick={() => localStorage.setItem("origin", "off")}
                type={ButtonType.secondary}
                size={
                  width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                }
                fullWidth
              >
                <img
                  src={twitter}
                  className={classNames("inline w-6")}
                  alt="Twitter icon"
                />
                <span className="pl-3 md:pl-6">Log in with Twitter</span>
              </Button>
            </a>
          </div> */}
          {/*    <div className="h-16 flex items-center justify-center">
            <a
              href={getSocialAuthorizeUrl("telegram")}
              className="w-full block"
            >
              <Button
                onClick={() => localStorage.setItem("origin", "off")}
                type={ButtonType.secondary}
                size={
                  width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium
                }
                fullWidth
              >
                <img
                  src={telegram}
                  className={classNames("inline w-6")}
                  alt="Telegram icon"
                />
                <span className="pl-3 md:pl-6">Log in with Telegram</span>
              </Button>
            </a>
          </div> */}
        </>
      </div>
    </div>
  );
};
export default LoginSectionButtons;

import { UserData, UserNotification } from "models/Interfaces";
import { instanceOfUserData } from "utils/instanceOf";
import {
  PlatformType,
  SocialChannel,
  NotificationType,
  CategoryType,
  BlockchainType,
} from "../../models/Enums";
import Notification, { AlertConfiguration } from "../../models/Notification";

/**
 * Currently this module mocks what could be an api integration.
 * The current implementation is only for demonstration purposes.
 */
const delayMilliseconds = 500;

//! the function bellow is not being used as it was
export function getNotifications(id?: number): Promise<Notification[]> {
  let notifications = JSON.parse(
    localStorage.getItem("notifications") || "[]"
  ) as Notification[];

  if (id)
    notifications = notifications.filter(
      (notification) => Number(notification.notificationId) === id
    );

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(notifications);
    }, delayMilliseconds);
  });
}

//! the function bellow is not being used as it was
export async function createNewNotification(
  userData: UserData | null,
  userId: string,
  status: string,
  chainId: BlockchainType,
  category: CategoryType,
  type: NotificationType,
  platform: PlatformType,
  socialChannels: SocialChannel[],
  alertConfiguration: AlertConfiguration
): Promise<UserData | undefined> {
  /* let notifications = JSON.parse(
    localStorage.getItem("notifications") || "[]"
  ) as Notification[];
  let notificationId: number = 0;
  notificationId = notifications.length
    ? Math.max(
        ...notifications.map((notification) =>
          Number(notification?.notificationId)
        )
      )
    : notificationId;
  notificationId = notificationId + 1; */

  let discordId = "",
    telegramId = "",
    twitterId = "";

  socialChannels?.map((s) => {
    if (s === SocialChannel.Discord) {
      discordId =
        userData?.socials?.filter(
          (s) => s.type === SocialChannel.Discord.toLowerCase()
        )[0].id || "";
    } /* else if (s === SocialChannel.Telegram) {
      telegramId =
        userData?.socials?.filter(
          (s) => s.type === SocialChannel.Telegram.toLowerCase()
        )[0].id || "";
    } */
  });

  try {
    const connection = await fetch(
      `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/notifications`,
      {
        method: "POST",
        body: JSON.stringify({
          userId,
          status,
          chainId,
          categoryId: category,
          projectId: platform,
          notificationTypeId: type,
          publicId: {
            groupId: "",
            channelId: "",
            type: "",
          },
          discordId,
          twitterId,
          telegramId,
          ...alertConfiguration.params,
        }),
        credentials: "include",
      }
    );
    const dataFromApi = await connection.json();
    return dataFromApi.data;
    /* if (
      typeof dataFromApi.data === "object" &&
      instanceOfUserData(dataFromApi.data)
    ) {
      setUser(dataFromApi.data);
    } */
  } catch (error) {
    console.error(error);
    return 
  }

  /* return new Promise(async (resolve) => {
    var newSubs = new Notification(
      String(notificationId),
      userId,
      status,
      chainId,
      category,
      type,
      platform,
      socialChannels,
      alertConfiguration
    );

    notifications.push(newSubs);

    localStorage.setItem("notifications", JSON.stringify(notifications));

    setTimeout(() => {
      resolve(newSubs);
    }, delayMilliseconds);
  }); */
}

export function editTheNotification(
  notification: Notification
): Promise<Notification> {
  let notifications = JSON.parse(
    localStorage.getItem("notifications") || "[]"
  ) as Notification[];

  return new Promise((resolve) => {
    var newSubs = new Notification(
      notification.notificationId,
      notification.userId,
      notification.status,
      notification.chainId,
      notification.categoryId,
      notification.notificationTypeId,
      notification?.projectId || undefined,
      notification.socialChannels,
      notification.alertConfiguration
    );

    notifications = notifications.map((elem) =>
      elem.notificationId === notification.notificationId
        ? {
            ...elem,
            status: notification.status,
            alertConfiguration: notification.alertConfiguration,
            socialChannels: notification.socialChannels,
          }
        : elem
    );
    //add to storage
    localStorage.setItem("notifications", JSON.stringify(notifications));

    setTimeout(() => {
      resolve(newSubs);
    }, delayMilliseconds);
  });
}

//! the function bellow is not being used as it was
export function removeTheNotification(
  notification: UserNotification
): Promise<void> {
  return new Promise((resolve) => {
    let notifications = JSON.parse(
      localStorage.getItem("notifications") || "[]"
    ) as Notification[];

    notifications.splice(
      notifications.findIndex(
        (elem) => elem.notificationId === notification.notificationId
      ),
      1
    );
    //add to storage
    localStorage.setItem("notifications", JSON.stringify(notifications));
    setTimeout(() => {
      resolve();
    }, delayMilliseconds);
  });
}

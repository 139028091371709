import React, { useEffect, useState } from "react";
import RightSection from "../components/RightSection";
import "./../style.css";
import "../i18n.js";
import Account from "../components/Account";
import BottomMenu from "../components/BottomMenu";
import { useTranslation } from "react-i18next";
import ContainerCard from "../components/SettingsCards/ContainerCard";
import { BlockchainDataProvider } from "contexts/blockchain-data";
import { Navbar } from "components/Navbar";

export enum SettingsTypes {
  otherSettings = "otherSettings",
  wallets = "wallets",
  socialAccounts = "socialAccounts",
}

export const SettingsPage: React.FC<{}> = () => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);

  useEffect(() => {
    setNavbarWidth(document.getElementById("sideMenu")?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <>
      <BlockchainDataProvider>
        <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
        <RightSection isCondensed={isCondensed}>
          <div className="relative md:w-full md:ml-0 -ml-2 flex items-center md:px-6 pb-8 py-5 max-w-6xl">
            <div className="px-10 sm:px-2 md:px-0 center flex-col w-full">
              <section className="flex flex-col w-full">
                <h1 className="mt-2 w-full justify-between mb-3 ml-4">
                  {t(`menu.settings`, { ns: "common" })}
                </h1>
                <ContainerCard
                  hasTitlePlus={true}
                  type={SettingsTypes.wallets}
                />
                <ContainerCard
                  hasTitlePlus={true}
                  type={SettingsTypes.socialAccounts}
                />
                <ContainerCard type={SettingsTypes.otherSettings} />
              </section>
            </div>
          </div>
        </RightSection>
        <BottomMenu />
      </BlockchainDataProvider>
    </>
  );
};

import {  UserData } from "models/Interfaces";


// short URL to apresentation
export const shortURL = (url: string) => {
  return url?.substring(0, 6)?.concat("...")
    .concat(url.substring(url.length, url.length - 6));
};

export const capitalizeName = (name: string) => {
  return name?.split(" ").map((opName) =>
      opName?.charAt(0).toUpperCase().concat(opName?.substring(1, opName.length))
    )
    .join(" ");
};

// return amount of wallet address (user)
export const countWallets = (user: UserData | null): number =>
  user?.wallets.length ?? 0;

export function nftUrl(url: string | undefined) {
  if (url?.toLowerCase().startsWith("https://")) {
    return url.replace(" ", "%20");
  } else if (url?.toLowerCase().startsWith("ipfs://")) {
    return `https://cloudflare-ipfs.com/ipfs/${url?.slice(7)}`;
    /* } else if (url.toLowerCase().startsWith("data:")) { base64 open directly from brownser
     */
  } else {
    return url;
  }
}
 
const convertBase64 = (query: string) => {
  return Buffer.from(query, "utf-8").toString("base64");
};

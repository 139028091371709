import {
  faDiscord,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { IconSettings } from "./IconSettings";
import Button, { ButtonType, ButtonSize } from "../Button";
import { Dispatch, SetStateAction } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";

type createObjCard = {
  type: string;
  openModal: () => void;
  setModalType: Dispatch<SetStateAction<any>>;
  getBtnId: Dispatch<SetStateAction<any>>;
  this?: string;
  numberOfWallets?: number;
  titleCard?: string;
  chainImage?: string;
  sectionTitle?: string;
  sectionText?: string;
  linkAccount?: string;
};

export const CardItem = (createObjCard: createObjCard) => {
  const [copy, setCopy] = useState(false);
  const openModal = (type: string) => {
    createObjCard.setModalType(type);
    createObjCard.getBtnId(createObjCard?.this);
    if (
      type === "trash" &&
      createObjCard.type === "wallets" &&
      createObjCard.numberOfWallets === 1
    )
      return;
    createObjCard.openModal();
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(createObjCard.sectionText?.toString() || "");
    setCopy(true);
  };
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);

  return (
    <>
      <div
        className={`sm:w-6/12 lg:w-4/12 2xl:w-4/12 
        `}
      >
        <div
          className={`h-36 px-6 py-4 shadow-icons rounded-lg bg-white sm:mx-4 md:mx-6 md:ml-0 my-5 sm:my-2`}
        >
          <div className="flex items-center">
            {(createObjCard.type === "socialAccounts" &&
              createObjCard.titleCard === "telegram" && (
                <FontAwesomeIcon
                  icon={faTelegram}
                  className={`text-telegram text-2xl mr-2`}
                />
              )) ||
              (createObjCard.titleCard === "twitter" && (
                <FontAwesomeIcon
                  icon={faTwitter}
                  className={`text-twitter text-2xl mr-2 `}
                />
              )) ||
              (createObjCard.titleCard === "discord" && (
                <FontAwesomeIcon
                  icon={faDiscord}
                  className={`text-discord text-2xl mr-2 `}
                />
              ))}
            {createObjCard.type === "wallets" && (
              <img
                src={createObjCard.chainImage ?? "./images/terra_icon.svg"}
                className="mr-2 w-10"
                alt="Terra Wallet"
              />
            )}
            <p className=" font-semibold flex-grow flex text-black text-base">
              {createObjCard?.titleCard
                ? createObjCard.titleCard.slice(0, 1).toUpperCase() +
                  createObjCard.titleCard.slice(1)
                : ""}
            </p>
            {createObjCard.type !== "otherSettings" ? (
              createObjCard.type !== "wallets" ? (
                <Button
                  onClick={() => openModal("trash")}
                  className="mx-1.5 flex p-0 m-0 "
                  type={ButtonType.backgroundNone}
                  size={ButtonSize.clear}
                >
                  <IconSettings
                    icon="trash"
                    iconStyle="fas"
                    fontSize="text-xs"
                    shadowIcons={true}
                    textColor="text-gold"
                  />
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => openModal("edit")}
                    className="mx-1.5 flex p-0 m-0 "
                    type={ButtonType.backgroundNone}
                    size={ButtonSize.clear}
                  >
                    <IconSettings
                      icon="pen"
                      iconStyle="fas"
                      shadowIcons={true}
                      textColor="text-gold"
                      fontSize="text-xs"
                    />
                  </Button>
                  <Button
                    onClick={() => openModal("trash")}
                    type={ButtonType.backgroundNone}
                    size={ButtonSize.clear}
                    className={`mx-1.5 flex p-0 m-0 ${
                      createObjCard.numberOfWallets === 1 &&
                      "opacity-50 cursor-not-allowed"
                    }`}
                  >
                    <IconSettings
                      icon="trash"
                      iconStyle="fas"
                      fontSize="text-xs"
                      shadowIcons={true}
                      textColor="text-gold"
                    />
                  </Button>
                </>
              )
            ) : (
              <NavLink
                className="mx-1.5 flex"
                id={createObjCard.this}
                to={
                  createObjCard?.linkAccount ? createObjCard.linkAccount : "/#"
                }
              >
                <IconSettings
                  icon="angle-right"
                  iconStyle="fas"
                  textColor="text-gray-extralight90"
                  fontSize="text-2xl"
                  fontMobile="text-xl"
                />
              </NavLink>
            )}
          </div>
          <div
            className={`pt-4 ${
              createObjCard.type === "otherSettings" && "hidden sm:block pt-5"
            }`}
          >
            <p
              className={`text-md ${createObjCard.type === "wallets" && "h-8"}`}
            >
              {createObjCard.sectionTitle}
            </p>
            {createObjCard.type === "wallets" ? (
              <div
                className="flex cursor-pointer"
                onClick={() => copyToClipboard()}
              >
                <p className="w-25 overflow-hidden text-sm pr-4" id="copy">
                  {createObjCard.sectionText?.slice(0, 10)}...
                  {createObjCard.sectionText?.slice(-4)}
                </p>
                {copy && (
                  <FontAwesomeIcon
                    icon={faCopy}
                    className={"bg-transparent text-gold"}
                  />
                )}
                {!copy && (
                  <FontAwesomeIcon
                    icon={faCopy}
                    className={"bg-transparent text-gray-extralight80"}
                  />
                )}
              </div>
            ) : (
              <p className="text-sm ">{createObjCard.sectionText}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
function classNames(arg0: string) {
  throw new Error("Function not implemented.");
}

import * as nearAPI from "near-api-js";

const { connect, keyStores, WalletConnection } = nearAPI;

export const connectNear = async (type: string) => {
  //const myKeyStore = new keyStores.BrowserLocalStorageKeyStore();

  const connectionConfig = {
    networkId: "testnet",
    deps: [],
    //keyStore: myKeyStore, // first create a key store
    nodeUrl: "https://rpc.testnet.near.org",
    //walletUrl: "https://wallet.testnet.near.org",
    walletUrl: "https://testnet.mynearwallet.com",
  };

  // connect to NEAR
  const nearConnection = await connect(connectionConfig);

  // create wallet connection
  const walletConnection = new WalletConnection(nearConnection, "my-app");

  // const walletConnection = new WalletConnection(nearConnection);
  walletConnection.requestSignIn({
    successUrl:
      type === "login"
        ? process.env.REACT_APP_LOGIN || "http://localhost:3000/"
        : process.env.REACT_APP_SIGNUP || "http://localhost:3000/signup",
  });
};

import { Connection, Installation } from "@terra-dev/wallet-types";
import classNames from "classnames";
import Button, { ButtonSize, ButtonType } from "components/Button";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const ConnectExtensionButton: React.FC<{
  type: Installation["type"] | Connection["type"];
  icon: Installation["icon"] | Connection["icon"];
  name: Installation["name"] | Connection["name"];
  identifier: Installation["identifier"] | Connection["identifier"];
  url: Installation["url"];
  walletFlow?: boolean;
  disabled?: boolean
}> = ({ type, icon, name, identifier, url, walletFlow = false, disabled }) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      <Fragment key={"connection" + type + identifier}>
        <a href={url} target="_blank" rel="noreferrer">
          <Button
            type={
              name === "XDEFI Wallet"
                ? ButtonType.highlighted
                : ButtonType.secondary
            }
            noFlexMobile={true}
            size={width < 640 ? ButtonSize.mediumShorter : ButtonSize.medium}
            fullWidth={width < 1024 ? true : false}
            className={`md:mx-0 ${walletFlow ? "my-3" : "my-6"}`}
            disabled={disabled}
          >
            <img
              src={icon}
              alt={`Install ${name}`}
              className={classNames("inline w-6")}
            />
            <span className="pl-6">
              Install {t(`wallet_types.${name}`, { ns: "common" })}
            </span>
          </Button>
        </a>
      </Fragment>
    </>
  );
};

import Button, { ButtonType } from "components/Button";
import Logo from "components/Logo";
import { ReactNode } from "react";

export interface ModalContent {
  logo?: boolean;
  modalImage?: string;
  modalTitle?: string;
  additionalTitle?: string;
  children?: ReactNode | void;
  description?: string;
  alignElements?: string;
  primaryButton?: string;
  secondaryButton?: string;
  primaryButtonOnClick?: React.MouseEventHandler;
  secondaryButtonOnClick?: React.MouseEventHandler;
}

const ModalAuth = ({
  logo,
  modalImage,
  modalTitle,
  additionalTitle,
  children,
  description,
  alignElements = "",
  primaryButton,
  secondaryButton,
  primaryButtonOnClick,
  secondaryButtonOnClick
}: ModalContent) => {
  return (
    <>
      {logo && <Logo logout={true} />}
      {modalImage && (
        <img src={modalImage} alt="" className={`w-8/12 md:w-4/12 m-8`} />
      )}
      {modalTitle && (
        <h1 className="font-semibold my-4 md:mb-8 text-center">{modalTitle}</h1>
      )}
      {additionalTitle && (
        <>
          <h2
            className={`font-normal ${alignElements} mb-2 w-11/12 md:w-9/12 text-center`}
          >
            {additionalTitle}
            <br />
          </h2>
          <h2 className="font-normal mb-8 w-11/12 md:w-9/12 text-center">
            {description}
          </h2>
        </>
      )}
      <div className="flex justify-evenly">
        <div className="mx-10">
          {primaryButton && (
            <Button type={ButtonType.cancel} onClick={primaryButtonOnClick}>{primaryButton}</Button>
          )}
        </div>
        <div className="mx-10">
          {secondaryButton && <Button onClick={secondaryButtonOnClick}>{secondaryButton}</Button>}
        </div>
      </div>
      <>{children}</>
    </>
  );
};

export default ModalAuth;

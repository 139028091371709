import { Coin } from '@terra-money/terra.js';
import { terra } from 'shared';

export async function getCoinPrice(from = "uluna", to = "uusd"): Promise<string  | undefined> {
    
    const offerCoin = new Coin(from, '1000000');
    return terra.market.swapRate(offerCoin, to)
        .then(c => {
            return (Number(c.amount) / 1000000).toFixed(2);
        });
};

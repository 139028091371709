import { useState } from "react";
import NotificationCard from "./NotificationCard";
import { useNotifications } from "contexts/notifications";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import Loader, { LoaderType } from "./Loader";
import { useTranslation } from "react-i18next";
import { IconSettings } from "./SettingsCards/IconSettings";
import Button, { ButtonSize, ButtonType } from "./Button";
import { useContextUser } from "contexts/user";
import { ConfigurationParams } from "models/Notification";
import PopUp, { PopupSize } from "./Popup";
import { EditNotification } from "./Notifications/Views/EditNotification";
import React from "react";
import { SocialChannel } from "../models/Enums";
import { UserNotification } from "models/Interfaces";
import Lottie from "react-lottie";
import animationData from "../assets/lotties/arrow.json";

export const Notifications: React.FC<{}> = () => {
  const notificationContext = useNotifications();
  const newNotificationFlow = useNewNotificationFlow();
  const [notificationToEdit, setNotificationToEdit] =
    useState<UserNotification>();
  const [showPopEdit, setShowPopEdit] = useState<boolean>(false);
  const [socialChannels, setSocialChannels] = useState<SocialChannel[]>([]);
  const [selectedValues, setSelectedValues] = useState<ConfigurationParams>({});
  const [status, setStatus] = useState(false);
  const { t } = useTranslation(["common", "enumerations"]);

  const { userData } = useContextUser();

  const handleEditNotification = (notification: UserNotification) => {
    setNotificationToEdit(notification);
    setShowPopEdit(true);
  };

  const saveDataNotification = async (
    deleteNotification?: boolean | undefined
  ): Promise<void> => {
    if (notificationToEdit) {
      deleteNotification
        ? notificationContext.removeNotification(
            notificationToEdit.notificationId
          )
        : notificationContext.editNotification(
            notificationToEdit.notificationId,
            userData?.userId ?? "",
            status ? "1" : "0",
            socialChannels,
            selectedValues
          );
    }

    setShowPopEdit(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="relative md:w-full flex items-center md:px-6 pb-8 py-5 max-w-6xl">
      <div className="px-2 md:px-0 center flex-col w-full">
        <section className="flex flex-col w-full">
          <div className="flex ">
            <h1 className="mt-2 w-full justify-between mb-3 ml-4">
              {t(`menu.notifications`, { ns: "common" })}
            </h1>
            <Button
              onClick={newNotificationFlow.beginCreateNewNotification}
              type={ButtonType.backgroundNone}
              size={ButtonSize.clear}
              className={`flex p-0 m-0 disabled:shadow-lg cursor:pointer`}
            >
              <IconSettings
                icon="plus"
                shadowIcons={true}
                iconStyle="fas"
                fontSize="text-base"
                textColor="text-gold"
                fontMobile="text-base"
                className="w-10 h-10 "
              />
            </Button>
          </div>
          {notificationContext.isLoadingNotifications && (
            <Loader type={LoaderType.fullScreen} />
          )}
          {!notificationContext.isLoadingNotifications &&
            !userData?.notifications && (
              <div className="flex w-full flex-wrap justify-end">
                <div className="mr-4 mt-4" style={{ transform: "scaleX(-1)" }}>
                  <Lottie options={defaultOptions} height={100} width={130} />
                </div>
              </div>
            )}
          {!notificationContext.isLoadingNotifications &&
            userData?.notifications && (
              <div className="flex w-full flex-wrap">
                {userData?.notifications?.map((notification) => (
                  <>
                    <NotificationCard
                      key={Number(notification.notificationId)}
                      notification={notification}
                      handleOnClick={() => handleEditNotification(notification)}
                      setOpacity={notification.notificationStatus === "0"}
                    />
                  </>
                ))}
              </div>
            )}
        </section>
        {showPopEdit && (
          <PopUp
            closeModal={() => setShowPopEdit(false)}
            size={PopupSize.largeXL}
          >
            <EditNotification
              setNotification={setNotificationToEdit}
              closeModal={() => setShowPopEdit(false)}
              notification={notificationToEdit}
              saveNotification={saveDataNotification}
              status={status}
              setStatus={setStatus}
              socialChannels={socialChannels}
              setSocialChannels={setSocialChannels}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </PopUp>
        )}
      </div>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { getSocialAuthorizeUrl } from "../../utils/handleLogin";
import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";
import twitter from "../../assets/images/twitter.svg";
import discord from "../../assets/images/discord.svg";
import telegram from "../../assets/images/telegram.svg";
import classNames from "classnames";
import { SocialChannel } from "models/Enums";
import { useContextUser } from "contexts/user";

type Props = {
  closeModal?: Function;
};

export const SocialAccounts = ({ closeModal }: Props) => {
  const { t } = useTranslation(["common"]);
  const { userData } = useContextUser();

  interface EachSocial {
    type: String;
    status: Number;
    username: String;
  }

  const hasSocial = (social: string) => {
    if (userData?.socials === null) {
      return false;
    } else {
      const socialExists = userData?.socials.filter(
        (eachSocial: EachSocial) => {
          return eachSocial.type === social && eachSocial.status === 1;
        }
      );
      return socialExists && socialExists.length > 0;
    }
  };

  const socialIcon = (social: string) => {
    if (social === SocialChannel.Discord.toLowerCase()) {
      return discord;
    } /* else if (social === SocialChannel.Twitter.toLowerCase()) {
      return twitter;
    }*/ /* else if (social === SocialChannel.Telegram.toLowerCase()) {
      return telegram; } */
  };

  return (
    <>
      <div className="md:px-2 sm:px-0 md:px-4 py-3">
        {Object.values(SocialChannel).map((eachSocial) => {
          return (
            <div className="h-16 flex items-center justify-center">
              <a
                href={
                  hasSocial(eachSocial.toLowerCase())
                    ? "javascript:void(0)"
                    : getSocialAuthorizeUrl(eachSocial.toLowerCase())
                }
                className="w-full block"
              >
                <Button
                  onClick={() => localStorage.setItem("origin", "on")}
                  justify={ButtonJustify.center}
                  type={ButtonType.secondary}
                  disabled={hasSocial(eachSocial.toLowerCase())}
                  size={ButtonSize.mediumShorter}
                  fullWidth
                >
                  <img
                    src={socialIcon(eachSocial.toLowerCase())}
                    className={classNames("inline w-6")}
                    alt={`${eachSocial.toLowerCase()} icon`}
                  />
                  <span className="pl-3 md:pl-6">
                    Log in with{" "}
                    {eachSocial.slice(0, 1) + eachSocial.slice(1).toLowerCase()}
                  </span>
                </Button>
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

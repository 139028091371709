import React, { MouseEventHandler } from "react";
import classNames from "classnames";

const Logo: React.FC<{
  onClick?: MouseEventHandler<HTMLDivElement>;
  condensed?: boolean;
  className?: string;
  logout?: boolean;
}> = ({ onClick, condensed = false, className, logout }) => {
  const classNameisEmpty = className === undefined || className === null;
  return (
    <div
      className={classNames(
        { "logo-main pt-4 pb-4 mx-auto": classNameisEmpty },
        { "px-8": !condensed && classNameisEmpty },
        { "px-4 condensed": condensed && classNameisEmpty },
        className
      )}
      onClick={onClick}
    >
      <img
        className={classNames({"mx-auto": logout}, {"max-w-13": !condensed})}
        src={
          condensed ? "/images/logo_icon.svg" : "/images/logo_transparent.png"
        }
        alt="Hermes Protocol"
      />
    </div>
  );
};
export default Logo;

import classNames from "classnames";
import { ReactNode } from "react";

export enum BalloonType {
  index = "index",
  info = "info",
}
export enum BalloonSize {
  smaller = "index",
}

export const Balloon: React.FC<{
  children: ReactNode;
  type: BalloonType;
  disabled?: Boolean;
  id?: string;
  size?: BalloonSize;
  noFlex?: boolean;
  noEffectHover?: boolean;
}> = ({
  children,
  type,
  disabled,
  id,
  size,
  noFlex = false,
  noEffectHover = false,
}) => {
  return (
    <>
      {type === BalloonType.index && (
        <span
          id={id}
          className={classNames(
            "rounded-full h-8 w-8 mr-4 flex items-center justify-center md:mr-10",
            { "bg-gold text-white": !disabled },
            { "bg-gray-extralight20 text-gold": disabled }
          )}
        >
          {children}
        </span>
      )}
      {type === BalloonType.info && (
        <span
          id={id}
          className={classNames(
            `bg-transparent flex items-center justify-center ml-1 md:ml-4 ${
              noFlex ? "content-center" : "justify-center flex items-center"
            } ${
              size === BalloonSize.smaller ? "sm:h-8 sm:w-8 w-6 h-6" : "h-8 w-8"
            }`
          )}
        >
          <span
            className={classNames(
              `bg-gold text-white rounded-full ${
                size === BalloonSize.smaller
                  ? "sm:h-4 sm:w-4 h-3.5 w-3.5"
                  : "h-4.5 w-4.5"
              }
                    ${
                      !noEffectHover &&
                      "cursor-pointer hover:bg-muddywaters-600 focus:bg-muddywaters-60"
                    } justify-center flex items-center text-xs shadow-lg`
            )}
          >
            {children}
          </span>
        </span>
      )}
    </>
  );
};

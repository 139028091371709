import React from "react";
import classNames from "classnames";
import BottomMenuItem from "./BottomMenuItem";
import Logo from "./Logo";

export enum ActiveMenu {
  dash = "dashboard",
  notifications = "notifications",
  feed = "feed",
  settings = "settings"
}

const BottomMenu: React.FC = () => {
  return (
    <div
      className={classNames("z-20 fixed bottom-0 left-0 right-0 h-26 bg-white shadow-bottom-menu lg:hidden")}
    >
      <div
        className={classNames(
          "bottom-menu px-9 flex flex-row justify-between items-center",
        )}
      >
        {/* <BottomMenuItem icon="dashboard" url="/dashboard" /> */}
        <BottomMenuItem icon="notifications" url="/notifications" />
        <Logo condensed={true} className={"p-0 m-0 w-10 h-10"} />
        {/* <BottomMenuItem icon="notification-feed" url="/notification" /> */}
        <BottomMenuItem icon="settings" url="/settings" />
      </div>
    </div>
  );
};

export default BottomMenu;

export enum PlatformType {
    Anchor = 'zb2_0001',
    TerraSwap = 'zb2_0003',
    Astroport = 'zb2_0004',
    Mirror = 'zb2_0006',
    Loop = 'zb2_0005',
    Validators = 'zb2_0011',
    General = 'zb2_0012',
    TFM = 'zb2_0019',
    PhoenixFinance = 'zb2_0020',
    Luart = 'zb2_0008',
    RandomEarth = 'zb2_0024',
    KnowhereArt = 'zb2_0025',
    OnePlanet = 'zb2_0026',
    Talis = 'zb2_0027',
    MessierArt = 'zb2_0028',
    AllDerivatives = 'zb2_9996',
    AllDEX = 'zb2_9997',
    AllNft = 'zb2_9992',
    //SayveProtocolYieldOptimizer="zb2_0032", 
    TerrnadoCashInfrastructure="zb2_0036",
    RESToadsNFT="zb2_0035",
    AtloLaunchpad='zb2_0039',
    MiawTraderDEX="zb2_0037",
    LunaverseDEX='zb2_0038',
    RefFinance='zb2_0029',
    Trisolaris='zb2_0030',
    Paras='zb2_0031',
    Mintbase='zb2_0032'
};

export enum BlockchainType{
    TerraClassic = 'zb1_001',
    Terrav2 ='zb1_002',
    Near ='zb1_003'
}

export enum CategoryType {
    Blockchain = 'zb3_0004',
    Derivatives = 'zb3_0002',
    Dex = 'zb3_0001',
    NFT = 'zb3_0003',
    Launchpad='zb3_0005',
    Lending='zb3_0006',
    YieldStrategies='zb3_0007',
    Payments='zb3_0008',
    StakingPool='zb3_0009', 
    InfraStructure='zb3_0010'
};

export enum NotificationType {
    //LoanLiquidation = 'LOAN_LIQUIDATION',
    PriceAlert = '0021',
    //AirdropReady = 'AIRDROP_READY',
    //LunaClaim = 'LUNA_CLAIM',
    GovernancePoll = '0007',
    //NewToken = 'NEW_TOKEN',
    //LockdropUnlock = 'LOCKDROP_UNLOCK',
    //NewMasset = 'NEW_MASSET',
    //NewBid = 'NEW_BID',
    //NewMint = 'NEW_MINT',
    //NftMonitor = 'NFT_MONITOR',
    //FloorMonitor = 'FLOOR_MONITOR',
    WalletWatcher = '0024',
    NftMonitor = '0020',
    ProfileChanges = '0003'
}

export enum ScopeNotification {
    myWallet = 'zc5_0001',    
    unique = 'zc5_0002',
}


export enum SocialChannel {
    //Twitter = 'TWITTER',
    Discord = 'DISCORD',
/*     Telegram = 'TELEGRAM', */
};

export enum Social {
    discord = 'discord',
    twitter = 'twitter',
/*     telegram = 'telegram', */
  }

export enum ContractPairs {
    UlunaUusd = 'ULUNA_UUSD',
    UlunaUkrw = 'ULUNA_UKRW',
    BLunaUluna = 'BLUNA_ULUNA'
}
 
export enum NftMonitor {
    buySell = "zc6_0001",
    list = "zc6_0002",
    cancelList = "zc6_0003",
    mint = "zc6_0004",
    bid = "zc6_0005",
    cancelBid = "zc6_0006",
    changeBid = "zc6_0007",
    auction = "zc6_0008",
    cancelAuction = "zc6_0009",
  }

  export enum Protocol {
    MainNet = "mainNet",
    Classic = 'Classic'
  }

  export enum NotAllowed {
    list = "list",
    cancelList = "cancelList",
    bid = "bid",
    cancelBid = "cancelBid",
    changeBid = "changeBid",
    auction = "auction",
    cancelAuction = "cancelAuction",
  }
  
 
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { UserNotification } from "models/Interfaces";
import { ConfigurationParams } from "models/Notification";
import { formatPresentation } from "models/Utils/terra";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Data {
  ConfigsParameters: ConfigurationParams;
  crypto1: string;
  crypto2: string;
  imageToken1: string;
  imageToken2: string;
}
export const PriceAlert = ({
  setValues,
  disabledBtn,
  notification,
}: {
  setValues: Dispatch<SetStateAction<any>>;
  disabledBtn?: Dispatch<SetStateAction<any>>;
  notification?: UserNotification;
}) => {
  const { t } = useTranslation(["common", "enumerations"]);

  const [configs, setConfigs] = useState<Data>({
    ConfigsParameters: {
      pair: [],
      type: "",
      value: 0,
      inverted: false,
    },
    crypto1: "",
    crypto2: "",
    imageToken1: "",
    imageToken2: "",
  });
  const [dataTokens, setDataTokens] = useState<any>(null);
  const [pair, setPair] = useState<any>(null);
  const [type, setType] = useState<any>("");
  const [value, setValue] = useState<any>(0);
  const [inverted, setInverted] = useState(false);
  const { priceTypes } = useContextBlockchainData();
  const { selectedNotificationBlockchain } = useNewNotificationFlow();

  const handleSelectedContractPair = (event: any) => {
    const target = event.target as HTMLSelectElement;
    const optionSelectedData =
      target.options[event.target.selectedIndex].dataset;
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { pair: [target?.value] }
    );
    setPair(target?.value);
    setConfigs({
      ...configs,
      ConfigsParameters: newConfigurations,
      imageToken1: optionSelectedData?.imagetoken1 || "",
      imageToken2: optionSelectedData?.imagetoken2 || "",
      crypto1: optionSelectedData?.crypto1 || "",
      crypto2: optionSelectedData?.crypto2 || "",
    });
    checkData(newConfigurations);
  };

  useEffect(() => {
    (async () =>
      (selectedNotificationBlockchain || notification) &&
      (await formatPresentation(
        selectedNotificationBlockchain || notification?.chainId || ""
      )))().then((data) => setDataTokens(data));
  }, []);

  const showOptions = () => {
    if (!configs.imageToken1 || !configs.imageToken2) startConfigs();
    const options = dataTokens.map((contract: any, selected: number) => {
      const key = Object.keys(contract)[0];
      const tokens = Object.values(contract);

      return tokens.map((el: any) => {
        return (
          <option
            value={key}
            data-crypto1={el?.[0]?.symbol}
            data-crypto2={el?.[1]?.symbol}
            data-imagetoken1={el?.[0]?.icon}
            data-imagetoken2={el?.[1]?.icon}
          >{`${el?.[0]?.symbol}/ ${el?.[1]?.symbol}`}</option>
        );
      });
    });
    return options;
  };

  // presentation image in first onload
  const startConfigs = () => {
    const contractOne = dataTokens[0];
    const key = Object.keys(contractOne)[0];
    Object.values(contractOne).map(
      (el: any) =>
        (el[0]?.icon || el[1]?.icon) &&
        setConfigs({
          ...configs,
          ConfigsParameters: { pair: [key], inverted: false, type: ">=" },
          imageToken1: el[0]?.icon,
          imageToken2: el[1]?.icon,
          crypto1: el[0]?.symbol,
          crypto2: el[1]?.symbol,
        })
    );
  };

  const handleValueAlertPrice = (e: any) => {
    const price = e.target as HTMLSelectElement;
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { value: Number(price?.value) }
    );
    setValue(price?.value);
    setConfigs({
      ...configs,
      ConfigsParameters: newConfigurations,
    });
    checkData(newConfigurations);
  };

  const checkData = (configurations: any) => {
    if (Number(configurations?.value) && configurations?.value > 0) {
      const formatObjeNotification = Object.assign(
        {},
        { ...configs.ConfigsParameters },
        {
          value: configurations?.value,
          inverted: configurations?.inverted,
          pair: configurations?.pair,
          type: configurations?.type,
        }
      );
      setValues({ ...formatObjeNotification });
      disabledBtn && disabledBtn(false);
    }
  };

  const setInvertedToken = () => {
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { inverted: !inverted }
    );
    setInverted(!inverted);
    setConfigs({
      ...configs,
      ConfigsParameters: newConfigurations,
    });
    checkData(newConfigurations);
  };

  const handleTypeAlertType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newConfigurations = Object.assign(
      {},
      { ...configs.ConfigsParameters },
      { type: e?.target.value }
    );

    if (e?.target?.value !== "") {
      setConfigs({
        ...configs,
        ConfigsParameters: newConfigurations,
      });
      setType(e?.target.value);
    }
    checkData(newConfigurations);
  };

  useEffect(() => {
    /* To get values from the notification for edit */
    setPair(
      notification ? notification.pair : configs?.ConfigsParameters?.pair || []
    );
    setInverted(
      notification
        ? notification.inverted
        : configs.ConfigsParameters.inverted
        ? true
        : false
    );
    setType(notification ? notification?.type : configs.ConfigsParameters.type);
    setValue(
      notification ? notification.value : configs.ConfigsParameters.value
    );
    if (notification) {
      let crypto1 = "",
        crypto2 = "",
        imagetoken1 = "",
        imagetoken2 = "";

      dataTokens &&
        dataTokens
          .filter(
            (contract: any, selected: number) =>
              Object.keys(contract)[0] === notification.pair[0]
          )
          .map((contract: any, selected: number) => {
            const key = Object.keys(contract)[0];
            const tokens = Object.values(contract);
            tokens.map((el: any) => {
              crypto1 = el?.[0]?.symbol;
              crypto2 = el?.[1]?.symbol;
              imagetoken1 = el?.[0]?.icon;
              imagetoken2 = el?.[1]?.icon;
            });
          });

      setConfigs({
        ...configs,
        ConfigsParameters: {
          pair: notification.pair,
          type: notification.type,
          value: notification.value,
          inverted: notification.inverted,
        },
        imageToken1: imagetoken1,
        imageToken2: imagetoken2,
        crypto1: crypto1,
        crypto2: crypto2,
      });
    }
  }, [dataTokens]);

  return (
    <div>
      {!notification && (
        <div className="pb-4 border-b border-black-line w-full ">
          <h3>{t("notifications.price_alert", { ns: "common" })}</h3>
        </div>
      )}
      <div className="mt-8">
        <div className="flex align-center w-full">
          <div className="w-full">
            <select
              value={pair}
              name="pair"
              onChange={handleSelectedContractPair}
              className="focus:border-gray-extralight60 focus:ring-0 selectTypes  focus:outline-none customScrollbar"
            >
              {dataTokens && showOptions()}
            </select>
          </div>
          {/*           <div className="md:w-4/12">
            <div className="text-center w-full">
              <p className="font-semibold">
                {t("alert_configuration_settings.inverted_tokens")}
              </p>
              <label htmlFor="inverted">
                <input
                  className=" checked:border-0 text-gold border-gold cursor-pointer transition-colors focus:ring-0 focus:outline-none"
                  type="checkbox"
                  id="inverted"
                  onChange={setInvertedToken}
                  checked={inverted}
                />
              </label>
            </div>
          </div> */}
        </div>
      </div>
      {/*       <p className="mt-4 font-semibold">
        {t(`alert_configuration_settings.current_price`, {
          ns: "common",
        })}
      </p>
      <div className="mt-2 mb-6">
        <div className="font-semibold mr-4">{value || "--,--"}</div>
        <div className="text-xs block">
          {t(`alert_configuration_settings.last_update`, {
            ns: "common",
          })}
          : <span className="">11:17 10/05/2022</span>
        </div>
      </div> */}
      <p className="mt-7 font-semibold">
        {t(`alert_configuration`, { ns: "common" })}
      </p>
      <div className="mt-3 border border-gray-extralight80 rounded-8px">
        <div className="px-3 py-2 text-sm text-gray-dark font-semibold bg-gray-extralight20 text-center rounded-t-8px border-b border-gray-extralight80">
          {(configs.imageToken1 !== "" || configs.imageToken2 !== "") && (
            <div className="flex items-center justify-evenly">
              <div className="flex items-center justify-center w-2/6">
                <img
                  src={inverted ? configs.imageToken2 : configs.imageToken1}
                  alt={inverted ? configs.crypto2 : configs.crypto1}
                  className="w-8 h-8"
                />
                <span className="ml-2 mr-2">
                  {`${inverted ? configs.crypto2 : configs.crypto1}`}
                </span>
              </div>
              <div className="w-2/6">
                <FontAwesomeIcon
                  icon={faArrowsAltH}
                  className="text-3xl ml-2 mr-2 text-gold cursor-pointer"
                  onClick={setInvertedToken}
                />
              </div>
              <div className="flex items-center justify-center w-2/6">
                <span className="ml-2 mr-2">
                  {inverted ? configs.crypto1 : configs.crypto2}
                </span>
                <img
                  src={inverted ? configs.imageToken1 : configs.imageToken2}
                  alt={inverted ? configs.crypto1 : configs.crypto2}
                  className="w-7 h-7"
                />
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 px-3 py-5 pt-6">
          <div className="text-center m-auto">
            <div className="small">
              <div className="text-sm mb-1">
                {t(`alert_configuration_settings.alert_type`, {
                  ns: "common",
                })}
              </div>

              <div className="inline-block relative w-full mb-4">
                <select
                  className="focus:border-gray-extralight60 focus:ring-0 selectTypes  focus:outline-none customScrollbar"
                  value={type}
                  name="type"
                  onChange={(e) => handleTypeAlertType(e)}
                >
                  {priceTypes?.map((tp) => {
                    return (
                      <option value={tp.type}>
                        {t(`alert_configuration_settings.${tp.type}`, {
                          ns: "common",
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className="text-sm mb-1">
              {t(`alert_configuration_settings.alert_price`, {
                ns: "common",
              })}
            </div>
            <div>
              <input
                value={value}
                name="value"
                onChange={(e) => handleValueAlertPrice(e)}
                className="small text-center w-24 font-semibold text-gray-dark m-auto border-gray-light40"
                type="number"
                step=".05"
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { DeleteModal } from "components/Modals";
import PopUp, { PopupSize } from "components/Popup";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const DeleteAccount = () => {
  const { t } = useTranslation(["common"]);
  const [modalDeleteAccount, setModalDeleteAccount] = useState<boolean>(false);
  const [modalDeleteAccountConfirm, setModalDeleteAccountConfirm] =
    useState<boolean>(false);

  /* const showModalConfirm = () => {
    if (modalDeleteAccountConfirm) DeleteAccount();
    setModalDeleteAccount(false);
    setModalDeleteAccountConfirm(true);
  }; */
  
  const goToContactForm = () => {
    
  }

  const returnModal = (
    $text = t("settings.deleteAccountRedirect", { ns: "common" }),
    textBtnSubmit = "Contact us"
  ) => {
    return (
      <>
        <DeleteModal
          fontWeight="font-semibold"
          title={$text}
          setShowModal={
            modalDeleteAccount
              ? setModalDeleteAccount
              : setModalDeleteAccountConfirm
          }
          fn={() => setModalDeleteAccount(false)}
          textBtnSubmit={textBtnSubmit}
        />
      </>
    );
  };

  return (
    <div className="md:px-6 sm:px-4 pl-4 pr-8 py-4 sm:w-3/6">
      <h3 className="mb-8">{t(`settings.account`, { ns: "common" })}</h3>
      <div className="relative">
        <p className="absolute left-0 text-gray-500">Delete your account </p>
        <button
          className="absolute right-2 sm:right-0 text-gold"
          onClick={() => setModalDeleteAccount(true)}
        >
          {t("action_delete", { ns: "common" })}
        </button>
      </div>
      {modalDeleteAccount && (
        <PopUp
          closeModal={() => setModalDeleteAccount(false)}
          size={PopupSize.smallxs}
        >
          {returnModal()}
        </PopUp>
      )}
      {/* {modalDeleteAccountConfirm && (
        <PopUp closeModal={() => setModalDeleteAccountConfirm(false)}>
          {returnModal(
            t("settings.deleteAccountConfirm", { ns: "common" }),
            t("settings.deleteAccountButton", { ns: "common" })
          )}
        </PopUp>
      )} */}
    </div>
  );
};

import BottomMenu from "components/BottomMenu";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { IconSettings } from "../components/SettingsCards/IconSettings";
import RightSection from "../components/RightSection";
import { DeleteAccount } from "../components/Settings/DeleteAccount";
import { UserSettingsEdit } from "../components/Settings/UserSettingsEdit";
import { Navbar } from "components/Navbar";
import { useEffect, useState } from "react";

export const UserSettingPage: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const [isCondensed, setIsCondensed] = useState(false);
  const [navbarWidth, setNavbarWidth] = useState(0);

  useEffect(() => {
    setNavbarWidth(document.getElementById("sideMenu")?.clientWidth || 0);
  }, [isCondensed]);

  return (
    <>
      <Navbar setIsCondensed={setIsCondensed} isCondensed={isCondensed} />
      <RightSection isCondensed={isCondensed}>
        <div className="relative md:w-full flex items-center md:px-6 px-4 sm:max-w-6xl">
          <section className="min-h-screen sm:relative w-full">
            <div className="px-2 mt-8 md:px-0 w-full">
              <NavLink className="mx-1.5 flex" to={"/settings"}>
                <IconSettings
                  bgContainerTransparent={false}
                  icon="angle-left"
                  iconStyle="fas"
                  textColor="text-gray-extralight90"
                  fontSize="text-xl"
                  fontMobile="text-2xl"
                />
              </NavLink>
              <div
                className={`px-6 py-4 shadow-icons rounded-lg bg-white sm:mx-4 md:mx-6 md:ml-0 my-5 sm:my-2 h-auto w-full md:w-3/4 lg:w-2/4`}
              >
                <h1 className="mt-2 justify-between mb-4 ml-4">
                  {t(`settings.userSettings`, { ns: "common" })}
                </h1>
                <UserSettingsEdit />
              </div>
            </div>
            <div className="absolute bottom-20 w-full">
              <DeleteAccount />
            </div>
          </section>
        </div>
      </RightSection>
      <BottomMenu />
    </>
  );
};

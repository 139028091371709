import { BlockchainType, PlatformType } from "models/Enums";
import React from "react";
import { useContextBlockchainData } from "contexts/blockchain-data";

const PlatformIcon: React.FC<{
  platform: PlatformType | undefined;
  blockchain: BlockchainType | undefined;
  className: string | undefined;
}> = ({ platform, blockchain, className }) => {
  const { projects, blockchains } = useContextBlockchainData();

  const platformIcon = projects?.filter((p) => {
    return p.project_market_id === platform;
  })[0]?.image_url;

  let blockchainIcon;

  switch (blockchain) {
    case BlockchainType.Terrav2:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/Terra.png";
      break;
    case BlockchainType.TerraClassic:
      blockchainIcon =
        "https://assets.hermesprotocol.io/img/projects/TerraClassic.png";
      break;
    case BlockchainType.Near:
      blockchainIcon = "https://assets.hermesprotocol.io/img/projects/near.png";
      break;
  }

  return (
    <img
      className={className}
      src={platformIcon ? platformIcon : blockchainIcon}
      alt=""
    />
  );
};

export default PlatformIcon;

import { PlatformType, SocialChannel, NotificationType, CategoryType, BlockchainType } from "./Enums";
import { Nft } from "./Interfaces";


class Notification {
  notificationId: string;
  userId: string;
  status: string;
  chainId: BlockchainType; 
  categoryId: CategoryType;
  notificationTypeId: NotificationType;
  projectId: PlatformType | undefined;
  alertConfiguration?: AlertConfiguration;
  socialChannels: SocialChannel[]; 
  constructor(
    notificationId: string,
    userId: string,
    status: string,
    chainId: BlockchainType,
    category: CategoryType,
    notificationTypeId: NotificationType,
    platform: PlatformType | undefined,
    socialChannels: SocialChannel[],
    alertConfiguration?: AlertConfiguration
    ) {
      this.notificationId = notificationId;
      this.userId = userId;
      this.status = status;
      this.chainId = chainId;
      this.categoryId = category;
      this.notificationTypeId = notificationTypeId;
      this.projectId = platform;
    this.alertConfiguration =alertConfiguration;
    this.socialChannels = socialChannels || [];
  }
}


/* export class AlertConfiguration {
  pair?: string;
  type?: string;
  value?: number;
  wallet?: string;
  

  constructor(pair?: string, type?: string, value?: number, wallet?: string) {
    this.pair = pair;
    this.type = type;
    this.value = value;
    this.wallet = wallet;
  }
} */

export interface ConfigurationParams {
  pair?: string[] | undefined;
  type?: string | undefined;
  value?: number | undefined;
  wallet?: string | undefined;
  scope?: string | undefined;
  walletAddress?:string | undefined;
  validatorAddress?: [] | undefined;
  monitorOptions?: [] | undefined;
  crypto1?: string | undefined;
  crypto2?: string | undefined;
  inverted?: boolean | undefined;
   nft?: Nft[] | undefined; 
  mention?: string | undefined;
  }

export class AlertConfiguration {
  params?: ConfigurationParams;

  constructor(params?: ConfigurationParams) {
    this.params = params;
  }
}
export default Notification;

import { DeleteModal } from "./Modals";
import { NavLink } from "react-router-dom";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useWallet } from "@terra-money/wallet-provider";
import { useNavigate } from "react-router-dom";
import ModalAuth from "./Auth/ModalAuth";
import { useTranslation } from "react-i18next";
import PopUp, { PopupSize } from "./Popup";
import { useContextUser } from "contexts/user";
import { Tooltip } from "./Tooltip";

const Account: React.FC<{
  condensed: boolean;
}> = ({ condensed }) => {
  const { disconnect } = useWallet();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const { t } = useTranslation(["common", "enumerations"]);
  const { userData, setUser, setIsLoggedIn } = useContextUser();

  const handleLogout = async () => {
    try {
      if (userData) {
        await fetch(
          `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/auth/logout`,
          {
            method: "POST",
            body: JSON.stringify({ userId: userData.userId }),
            credentials: "include",
          }
        );
        disconnect();
        setUser(null, false);
        /* setIsLoggedOut(true);
        setIsOpenModal(true); */
        sessionStorage.removeItem("socialsLogin");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const redirectToLogin = () => {
    setTimeout(() => {
      setUser(null, false);
    }, 1500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      <div
        className={classNames(
          `w-full flex justify-between my-2 ${width < 1024 ? "mx-2" : "mx-5"}
           lg:items-center lg:mx-6 lg:mb-4`,
          { "flex-row": !condensed },
          { "lg:flex-col lg:mt-10": condensed }
        )}
      >
        {width >= 1024 && (
          <>
            <NavLink
              className="flex items-center flex-grow"
              to="/settings/user"
            >
              <img
                src="/images/account.svg"
                alt="Hermes Protocol account"
                className="w-10"
              />
              {!condensed && (
                <div className="px-0 account-user-name text-sm text-gray-dark font-semibold flex-grow mx-4">
                  {userData?.clientAlias
                    ? userData?.clientAlias
                    : "Hermes User"}
                </div>
              )}
            </NavLink>
            <div>
              <button
                onClick={handleLogout}
                className={"z-10 flex flex-col items-center"}
              >
                <FontAwesomeIcon
                  className={classNames(
                    `text-gold float-right lg:text-gray-light40 lg:text-2xl text-xl lg:float-none mb-2 sm:mb-0`,
                    {
                      "lg:mt-0": condensed && !(width < 1024),
                    },
                    {
                      "sm:mt-1": width < 1024,
                    }
                  )}
                  icon={faSignOutAlt}
                />
                <span className="text-sm text-gray-light40 font-extrabold	">
                  Log Out
                </span>
              </button>
            </div>
          </>
        )}
        {width < 1024 && (
          <button onClick={handleLogout} className={"z-10 flex-grow"}>
            {width < 1024 && (
              <div
                className={
                  "underline text-black-transparent50 text-base absolute sm:left-16 left-10"
                }
              >
                Log Out
              </div>
            )}

            <FontAwesomeIcon
              className={classNames(
                `text-gold float-right lg:text-gray-light40 lg:text-2xl text-xl lg:float-none mb-2 sm:mb-0`,
                {
                  "sm:mt-1": width < 1024,
                }
              )}
              icon={faSignOutAlt}
            />
          </button>
        )}
        {isOpenModal && (
          <>
            {!isLoggedOut && (
              <PopUp
                closeModal={() => setIsOpenModal(false)}
                size={PopupSize.smallxs}
              >
                <DeleteModal
                  fn={() => handleLogout()}
                  textBtnSubmit={t("logoutBtn", { ns: "common" })}
                  title={t("logout", { ns: "common" })}
                  setShowModal={setIsOpenModal}
                />
              </PopUp>
            )}
            {/* {isLoggedOut && (
              <PopUp size={PopupSize.smallxs}>
                <div className="text-center">
                  <ModalAuth
                    alignElements="mx-auto"
                    modalTitle={t("modal_content.user_logs_out.modal_title", {
                      ns: "enumerations",
                    })}
                    additionalTitle={t(
                      "modal_content.user_logs_out.additional_title",
                      { ns: "enumerations" }
                    )}
                    logo={true}
                  >
                    {redirectToLogin()}
                  </ModalAuth>
                </div>
              </PopUp>
            )} */}
          </>
        )}
      </div>
    </>
  );
};
export default Account;

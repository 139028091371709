import Loader, { LoaderType } from "components/Loader";
import { useContextUser } from "contexts/user";
import { useEffect, useState } from "react";
import PopUp from "components/Popup";
import { useTranslation } from "react-i18next";
import { SettingsTypes } from "views/Settings";
import { Warning } from "./Warning";
import { instanceOfUserData } from "utils/instanceOf";

type Props = {
  setShowModal: (show: boolean) => void;
  fn?: () => void;
  id?: string;
  title?: string | undefined;
  type?: string;
  textBtnCancel?: string;
  textBtnSubmit?: string;
  fontWeight?: string;
  boldTitle?: string;
  updateView?: () => void;
};
export const DeleteModal = ({
  setShowModal,
  fn,
  id,
  title,
  type,
  textBtnCancel,
  textBtnSubmit,
  updateView,
  fontWeight,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const [showWarning, setshowWarning] = useState<boolean>(false);
  const { userData, setUser } = useContextUser();
  const [isLoading, setIsLoading] = useState(false);

  const deleteSocialsAccount = async () => {
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/social/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            userId: userData && userData.userId,
            socialId: userData?.socials?.filter((s) => s.type === id)[0].id,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser && setUser(dataFromApi.data);
      }
      if (updateView) updateView();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const deleteWallet = async () => {
    setIsLoading(true);
    try {
      const connection = await fetch(
        `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/wallets/${id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            userId: userData && userData.userId,
          }),
          credentials: "include",
        }
      );
      const dataFromApi = await connection.json();
      if (
        typeof dataFromApi.data === "object" &&
        instanceOfUserData(dataFromApi.data)
      ) {
        setUser && setUser(dataFromApi.data);
      }
      if (updateView) updateView();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleClick = () => {
    fn && fn();
    (type === SettingsTypes.wallets || type === SettingsTypes.socialAccounts) &&
      warning();
  };

  useEffect(() => {
    boldTransform(title);
  });
  const warning = () => {
    setshowWarning(true);
  };

  const boldTransform = (title: string | undefined) => {
    if (title === undefined) return;
    let res = title.replace("[*", "<b>").replace("*]", "</b>");
    res = res.replace("[_", "<u>").replace("_]", "</u>");
    const el = document.getElementById("title");
    if (el) return (el.innerHTML = res);
  };

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}

      <div>
        {showWarning && (
          <>
            <Warning
              title={t("modal_content.warning.title", { ns: "enumerations" })}
              text={
                type === SettingsTypes.wallets
                  ? t("modal_content.warning.delete_wallet", {
                      ns: "enumerations",
                    })
                  : t("modal_content.warning.delete_social_account", {
                      ns: "enumerations",
                    })
              }
              iconWarning={true}
            />
            <div className="mt-6">
              <div className="flex justify-end cursor-pointer">
                <p
                  className="mx-2 text-base underline"
                  onClick={() => setShowModal(false)}
                >
                  {t("action_cancel", {
                    ns: "common",
                  })}
                </p>
                <p
                  className="mx-2 text-base underline text-gold"
                  onClick={() =>
                    (type === SettingsTypes.wallets && deleteWallet()) ||
                    (type === SettingsTypes.socialAccounts &&
                      deleteSocialsAccount())
                  }
                >
                  {t("action_delete", { ns: "common" })}
                </p>
              </div>
            </div>
          </>
        )}

        {!showWarning && (
          <>
            <div
              className={`py-2 px-4 border-solid border-gray-300 rounded-t `}
            >
              <h5
                id="title"
                className={`sm:text-xl w-full text-lg ${
                  fontWeight && fontWeight
                } px-10-unset text-center`}
              >
                {boldTransform(title)}
              </h5>
            </div>
            <div className="flex justify-evenly py-2 rounded-b">
              <button
                className="text-white bg-gold background-transparent px-6 py-2 text-base outline-none lg:hover:shadow-lg rounded focus:outline-none mb-1"
                type="button"
                onClick={() => setShowModal(false)}
              >
                {textBtnCancel ?? "Cancel"}
              </button>
              <button
                className="text-black bg-gray-extralight60 active:bg-yellow-700 text-base px-6 py-2 rounded lg:hover:shadow-lg outline-none focus:outline-none mb-1"
                type="button"
                onClick={handleClick}
              >
                {textBtnSubmit === "Contact us" && (
                  <a
                    href="https://hermesprotocol.io/contact-us"
                    target="_blank"
                  >
                    {textBtnSubmit ?? t("action_delete", { ns: "common" })}
                  </a>
                )}
                {(textBtnSubmit !== "Contact us" && textBtnSubmit) ??
                  t("action_delete", { ns: "common" })}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

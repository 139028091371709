import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import {
  BlockchainType,
  CategoryType,
  NotificationType,
  PlatformType,
} from "models/Enums";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const SelectAlertTypes = ({
  blockchainId,
  setNotificationSetup,
}: {
  blockchainId: BlockchainType;
  setNotificationSetup: Dispatch<SetStateAction<any>>;
}) => {
  const newNotificationFlow = useNewNotificationFlow();
  const { categories, projects, types } = useContextBlockchainData();
  const { t } = useTranslation(["common", "enumerations"]);
  const [configsAlert, setConfigsAlert] = useState<
    [CategoryType, PlatformType, NotificationType] | []
  >([]);

  const typesByChainId = () => {
    if (!blockchainId) return;
    return types?.filter((p) => p.chain_id === blockchainId);
  };

  useEffect(() => {
    if (newNotificationFlow.selectedNotificationType)
      setConfigsAlert([
        newNotificationFlow?.selectedCategoryType as CategoryType,
        newNotificationFlow.selectedPlatformType as PlatformType,
        newNotificationFlow?.selectedNotificationType as NotificationType,
      ]);
  }, [newNotificationFlow]);
  
  // get compatible projects by blockchain ID
  const projectsByChainId = () => {
    if (!blockchainId) return;
    return projects?.filter(
      (p) =>
        newNotificationFlow?.selectedNotificationBlockchain === p.chain_id ||
        p.chain_id === blockchainId
    );
  };

  // get and set configs
  const handleAlertData = (dataArray: string) => {
    const data = dataArray?.split(",");
    setConfigsAlert([
      data[0] as CategoryType,
      data[1] as PlatformType,
      data[2] as NotificationType,
    ]);
  };

  useEffect(() => {
    configsAlert.length && setNotificationSetup(configsAlert);
  }, [configsAlert]);

  const returnNameById = (
    type: typeof CategoryType | typeof PlatformType | typeof NotificationType,
    id: CategoryType | PlatformType | NotificationType
  ) => {
    id =
      (type === NotificationType && (id.slice(-4) as NotificationType)) || id;
    const nameCategory = Object.entries(type).find(([key, value]): string => {
      return value === id && value;
    })?.[0];

    const keyName =
      (type === CategoryType && "category_types") ||
      (type === PlatformType && "platform_types") ||
      (type === NotificationType && "notification_types");

    if (nameCategory !== undefined) {
      return t(`${keyName}.${nameCategory}`, {
        ns: "enumerations",
      });
    } else {
      return "";
    }
  };

  return (
    <div className="my-12">
      <select
        onChange={(event) => handleAlertData(event?.target?.value)}
        className="focus:border-gray-extralight60 focus:ring-0 selectTypes  focus:outline-none customScrollbar"
      >
        <option value="" selected disabled className="font-extrabold">
          {t("notifications.select_type", { ns: "common" })}
        </option>
        {categories?.map((c) => (
          <>
            {true && (
              <optgroup
                className="relative"
                label={returnNameById(
                  CategoryType,
                  c?.category_id as CategoryType
                )}
              >
                {projectsByChainId()
                  ?.filter((p) => p.category?.includes(c.category_id))
                  .map((pc) => {
                    const nameProject = returnNameById(
                      PlatformType,
                      pc?.project_market_id as PlatformType
                    );
                    return (
                      <>
                        {nameProject !== "" && (
                          <option className="forceLeft font-bold " disabled>
                            {returnNameById(
                              PlatformType,
                              pc?.project_market_id as PlatformType
                            )}
                          </option>
                        )}
                        {typesByChainId()
                          ?.filter(
                            (f) => f.project_market_id === pc.project_market_id
                            //&& f.chain_id === blockchainId
                          )
                          .map((alert) => {
                            return (
                              !alert.only_BE && (
                                <>
                                  <option
                                    value={[
                                      alert?.category_id as CategoryType,
                                      alert?.project_market_id as PlatformType,
                                      alert?.notification_type_id as NotificationType,
                                    ]}
                                    className="pl-4 "
                                    selected={
                                      newNotificationFlow?.selectedCategoryType ===
                                        alert.category_id &&
                                      newNotificationFlow?.selectedPlatformType ===
                                        alert.project_market_id &&
                                      newNotificationFlow?.selectedNotificationType ===
                                        alert.notification_type_id
                                    }
                                  >
                                    &nbsp;&nbsp;&nbsp;
                                    {returnNameById(
                                      NotificationType,
                                      alert.notification_type_id as NotificationType
                                    )}
                                  </option>
                                </>
                              )
                            );
                          })}
                      </>
                    );
                  })}
              </optgroup>
            )}
          </>
        ))}
      </select>
      {configsAlert[2] && (
        <div className="pt-2 px-2 flex text-sm">
          <span>
            {configsAlert[0] && (
              <>
                <span className="block sm:inline ">
                  <b className="text-gold">Category: </b>
                  {returnNameById(
                    CategoryType,
                    configsAlert[0] as CategoryType
                  )}
                </span>
              </>
            )}

            {configsAlert[1] && (
              <>
                <span className="block sm:inline">
                  <b className="text-gold"> Platform: </b>
                  {returnNameById(
                    PlatformType,
                    configsAlert[1] as PlatformType
                  )}
                </span>
              </>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import { useContextUser } from "contexts/user";
import { BlockchainType } from "models/Enums";
import { Blockchain } from "models/Interfaces";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  handleCancel(): void;
  handleGoToNext(blockchainType: BlockchainType): void;
};
const BlockchainStep = ({ handleCancel, handleGoToNext }: Props) => {
  const { blockchains } = useContextBlockchainData();
  const { t } = useTranslation(["common"]);
  const newNotificationFlow = useNewNotificationFlow();
  const [blockchainChoosen, setBlockchainChoosen] = useState<string>(
    newNotificationFlow.selectedNotificationBlockchain || ""
  );

  const { userData } = useContextUser();
  // set status notification
  useEffect(() => {
    setStatusInFlow();
  }, []);

  // check if has Channels
  const setStatusInFlow = () => {
    newNotificationFlow.setStatusNotification(!userData?.socials ? "0" : "1");
    return !userData?.socials ? 0 : 1;
  };

  const isValidToSaveBlockchain = (chainId: string): string | boolean => {
    const objectChain = blockchains?.filter((c: Blockchain) => {
      return c?.chain_id === chainId;
    });
    if (!objectChain?.length) return false;
    return objectChain[0]?.chain_id;
  };
  const blockchainOptions = () => {
    return blockchains
      ?.filter((b) => b.chain_id === BlockchainType.Near)
      .map((blockchain) => {
        return (
          <option value={blockchain.chain_id} key={blockchain.chain_id}>
            {t(`blockchains_types.${blockchainName(blockchain.chain_id)}`, {
              ns: "enumerations",
            })}
          </option>
        );
      });
  };

  const blockchainName = (chainID: string): string => {
    return (
      Object.entries(BlockchainType).find(([key, value]) => {
        return value === chainID;
      })?.[0] || ""
    );
  };

  const handleGoToNextClick = () => {
    return blockchainChoosen && !isValidToSaveBlockchain(blockchainChoosen)
      ? false
      : handleGoToNext(blockchainChoosen as BlockchainType);
  };

  return (
    <>
      <div className="w-full">
        <div className="my-12 ">
          <select
            value={blockchainChoosen}
            className={`focus:border-gray-extralight60 focus:ring-0 focus:outline-none`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setBlockchainChoosen(e?.target?.value)
            }
          >
            <option value="" selected disabled>
              {t("notifications.choose_blockchain", { ns: "common" })}
            </option>
            {blockchainOptions()}
          </select>
        </div>
        <div className="flex justify-end ">
          <Button
            onClick={handleCancel}
            type={ButtonType.cancel}
            size={ButtonSize.smallWide}
            justify={ButtonJustify.center}
          >
            {t("action_cancel", { ns: "common" })}
          </Button>
          <Button
            className="ml-2"
            disabled={!blockchainChoosen}
            type={ButtonType.primary}
            size={ButtonSize.smallWide}
            justify={ButtonJustify.center}
            onClick={handleGoToNextClick}
          >
            {t("action_next", { ns: "common" })}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BlockchainStep;

import { ConnectType, useWallet, WalletStatus } from "@terra-money/use-wallet";
import { ModalState, SignUpStep } from "../../views/SignIn";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ConnectReadonlyButton } from "./ConnectReadonlyButton";
import { ConnectExtensionButton } from "./ConnectExtensionButton";
import { useContextUser } from "contexts/user";
import { WalletSettingsState } from "components/Modals/WalletsFlow";
import { LoginStep } from "views/Login";
import Loader, { LoaderType } from "components/Loader";

export enum AuthFlow {
  signup = "signup",
  login = "login",
}

/* Available wallet types. Update whenever new types of wallet connections are allowed */

export enum WalletTypes {
  station = "station",
  /* xdefiWallet = "xdefi-wallet",
  walletConnect = "WALLETCONNECT",
  leapWallet = "leap-wallet",
  falconWallet = "falcon-wallet",
  bitkeepWallet = "bitkeep-wallet" */
}

export const ConnectWallet: React.FC<{
  setModalContent: Dispatch<SetStateAction<any>>;
  modalContent?: string | null;
  setIsOpenModal?: Dispatch<SetStateAction<any>>;
  setCurrentStep?: Dispatch<SetStateAction<any>>;
  walletFlow?: boolean;
  authFlow?: AuthFlow;
  setNewWallet?: Dispatch<SetStateAction<any>>;
  newWallet?: string | null;
}> = ({
  setCurrentStep,
  setIsOpenModal,
  setModalContent,
  modalContent,
  walletFlow = false,
  authFlow,
  setNewWallet,
  newWallet,
}) => {
  const { availableConnections, availableInstallations, status, wallets } =
    useWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [hasUser, setHasUser] = useState(false);

  const checkUserExists = async () => {
    setIsLoading(true);
    /* This request returns a boolean, which indicates if a user with the connected wallet already exists */
    const connection = await fetch(
      `${process.env.REACT_APP_MIDDLEWARE_NEAR_URL}/fauna/user`,
      {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify({ walletAddress: wallets[0].terraAddress }),
      }
    );
    const dataFromApi = await connection.json();
    setHasUser(dataFromApi.data.user);
    setIsLoading(false);
    return dataFromApi.data.user;
  };

  const handleModal = (content: any) => {
    setIsOpenModal && setIsOpenModal(true);
    setModalContent(content);
  };

  const handleStep = (step: any) => {
    setCurrentStep && setCurrentStep(step);
  };

  useEffect(() => {
    if (window.location.pathname !== "/settings") {
      if (status === WalletStatus.WALLET_CONNECTED) {
        setIsLoading(true);
        checkUserExists().then((user) => {
          if (authFlow === AuthFlow.signup && user) {
            handleModal(ModalState.hasUser);
          } else if (authFlow === AuthFlow.signup && !user) {
            handleModal(ModalState.verify);
            handleStep(SignUpStep.verifyWallet);
          } else if (authFlow === AuthFlow.login && user) {
            handleStep(LoginStep.verifyWallet);
          } else {
            handleModal(ModalState.noUser);
          }
        });
      }
    } else if (newWallet !== null) {
      setModalContent(WalletSettingsState.ongoing);
    }
  }, [status]);

  return (
    <>
      {isLoading && <Loader type={LoaderType.fullScreen} />}
      <div
        className={` ${
          walletFlow ? "flex-col items-center " : "justify-evenly"
        } lg:flex flex-wrap w-full `}
      >
        {/* Searches through all available wallet connections of user and displays them. Displaying XDefi first */}
        {/* {availableConnections
          .filter(
            ({ type, identifier }) =>
              type !== ConnectType.READONLY &&
              Object.values(WalletTypes).find((w) => w === identifier) &&
              identifier === WalletTypes.xdefiWallet
          )
          .map(({ type, icon, name, identifier }) => (
            <ConnectReadonlyButton
              walletFlow={true}
              key={Math.random() * 0.5}
              type={type}
              identifier={identifier}
              name={name}
              icon={icon}
              disabled={isLoading || status === WalletStatus.WALLET_CONNECTED}
            />
          ))} */}
        {availableConnections
          .filter(
            ({ type, identifier }) =>
              type !== ConnectType.READONLY &&
              Object.values(WalletTypes).find((w) => w === identifier) /*  ||
                type === ConnectType.WALLETCONNECT */ /* &&
              identifier !== WalletTypes.xdefiWallet */
          )
          .map(({ type, icon, name, identifier }) => (
            <ConnectReadonlyButton
              walletFlow={true}
              type={type}
              key={Math.random() * 0.6}
              identifier={identifier}
              name={name}
              icon={icon}
              setNewWallet={setNewWallet}
              disabled={
                isLoading ||
                (window.location.pathname !== "/settings" &&
                  status === WalletStatus.WALLET_CONNECTED)
              }
            />
          ))}
        {/* Searches through all available wallet installations of user and displays them. Displaying XDefi first */}
        {/* {availableInstallations
          .filter(
            ({ type, identifier }) =>
              type === ConnectType.EXTENSION &&
              Object.values(WalletTypes).find((w) => w === identifier) &&
              identifier === WalletTypes.xdefiWallet
          )
          .map(({ type, identifier, name, icon, url }) => (
            <ConnectExtensionButton
              walletFlow={true}
              type={type}
              key={Math.random() * 0.7}
              identifier={identifier}
              name={name}
              icon={icon}
              url={url}
              disabled={isLoading || status === WalletStatus.WALLET_CONNECTED}
            />
          ))} */}
        {availableInstallations
          .filter(
            ({ type, identifier }) =>
              type === ConnectType.EXTENSION &&
              Object.values(WalletTypes).find((w) => w === identifier) /* &&
              identifier !== WalletTypes.xdefiWallet */
          )
          .map(({ type, identifier, name, icon, url }) => (
            <ConnectExtensionButton
              walletFlow={true}
              type={type}
              identifier={identifier}
              name={name}
              icon={icon}
              url={url}
              key={Math.random() * 0.8}
              disabled={isLoading || status === WalletStatus.WALLET_CONNECTED}
            />
          ))}
      </div>
    </>
  );
};

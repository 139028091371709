import { MouseEvent, ReactNode, useState } from "react";

export const Tooltip: React.FC<{
  children: ReactNode;
  content: ReactNode;
  isTooltipContentShow?: boolean;
  showTooltipOnLeave?: boolean;
}> = ({
  children,
  content,
  showTooltipOnLeave = false,
  isTooltipContentShow = false,
}) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false);
  const [mouseLocation, setMouseLocation] = useState({
    x: 0,
    y: 0,
  });

  const handleMouseEnter = (event: MouseEvent) => {
    setMouseLocation({
      x: event.pageX,
      y: event.pageY,
    });
    setIsTooltipShown(true);
  };

  const handleMouseLeave = (event?: React.MouseEvent) => {
    if (!showTooltipOnLeave) setIsTooltipShown(false);
    else {
      document.addEventListener("click", () => setIsTooltipShown(false), false);
    }
  };

  return (
    <>
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </span>
      <div
        className="fixed bg-white max-w-1/5 text-sm rounded p-1.5 font-semibold shadow-lg"
        style={{
          display: isTooltipShown ? "block" : "none",
          top: mouseLocation.y,
          left: mouseLocation.x,
        }}
      >
        {content}
      </div>
    </>
  );
};

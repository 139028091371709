import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, {
  ButtonJustify,
  ButtonSize,
  ButtonType,
} from "components/Button";
import { PriceAlert } from "components/Notifications/NotificationsType/PriceAlert";
import SocialChannels from "components/Notifications/Utils/SocialChannels";
import { useContextBlockchainData } from "contexts/blockchain-data";
import { useNewNotificationFlow } from "contexts/new-notification-flow";
import {
  BlockchainType,
  CategoryType,
  NotificationType,
  PlatformType,
  SocialChannel,
} from "models/Enums";
import { AlertConfiguration, ConfigurationParams } from "models/Notification";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GovernancePoll,
  WalletWatcher,
  ProfileChanges,
  NFTMonitor,
} from "../../NotificationsType/index";

export const ConfigurationStep: React.FC<{
  handleCancel(): void;
  handleGoToNext(
    channels: SocialChannel[],
    alertConfiguation: AlertConfiguration
  ): void;
  handleGoBack(): void;
  currentCategory: CategoryType;
  currentPlatform: PlatformType;
  currentType: NotificationType;
  isCreatingNotification: boolean;
  blockchain: BlockchainType;
}> = ({
  currentCategory,
  currentPlatform,
  currentType,
  blockchain,
  handleCancel,
  handleGoToNext,
  handleGoBack,
  isCreatingNotification,
}) => {
  const { t } = useTranslation(["common", "enumerations"]);
  const { types, projects } = useContextBlockchainData();
  const newNotificationFlow = useNewNotificationFlow();
  const [disabledButton, setDisableButton] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<ConfigurationParams>({});

  const [socialChannels, setSocialChannels] = useState<SocialChannel[]>([]);

  useEffect(() => {
    setDisableButton(true);
  }, []);

  const handleBack = () => {
    handleGoBack();
  };

  const handleGoToNextClick = () => {
    if (NotificationType.GovernancePoll === currentType.slice(-4)) {
      let govAddress = projects?.filter(
        (p) => p.project_market_id === currentPlatform
      )[0].gov_address;
      handleGoToNext(
        socialChannels,
        new AlertConfiguration({
          ...selectedValues,
          walletAddress: govAddress,
          mention: "",
        })
      );
    } else {
      handleGoToNext(
        socialChannels,
        new AlertConfiguration({ ...selectedValues })
      );
    }
  };

  return (
    <>
      <div className="pb-3 w-full">
        {types
          ?.filter(
            ({ project_market_id, notification_type_id }) =>
              project_market_id === newNotificationFlow.selectedPlatformType &&
              notification_type_id ===
                newNotificationFlow.selectedNotificationType
          )
          .map((el) => {
            let typeNotification = el.notification_type_id.slice(-4);
            /* Governance Pool Alert */
            return (
              (NotificationType.GovernancePoll === typeNotification && (
                <div>
                  <GovernancePoll disabledBtn={setDisableButton} />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              /* Wallet Watcher  */
              (NotificationType.WalletWatcher === typeNotification && (
                <div>
                  <WalletWatcher
                    disabledBtn={setDisableButton}
                    setWallet={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) /* Profile Changes Alert */ ||
              (NotificationType.ProfileChanges === typeNotification && (
                <div>
                  <ProfileChanges
                    disabledBtn={setDisableButton}
                    setValues={setSelectedValues}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              /*  NFT Monitor Alert */
              (NotificationType.NftMonitor === typeNotification && (
                <div>
                  <NFTMonitor
                    disabledBtn={setDisableButton}
                    setValues={setSelectedValues}
                    selectedValues={selectedValues}
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              )) ||
              (NotificationType.PriceAlert === typeNotification && (
                <div>
                  <PriceAlert
                    setValues={setSelectedValues}
                    disabledBtn={setDisableButton}
                  />
                  <SocialChannels
                    socialChannelsData={socialChannels}
                    setSocialChannels={setSocialChannels}
                  />
                </div>
              ))
            );
          })}
      </div>
      <div className="flex justify-between w-full justify-center sm:pt-2 pt-0">
        <div>
          <Button
            onClick={handleBack}
            type={ButtonType.cancel}
            size={ButtonSize.square}
          >
            <div>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            {/* after to add on hover in icon btn, show this information in desktop     */}
            <span className="text-sm mx-1 hidden">Back</span>
          </Button>
        </div>
        <div>
          <div className="flex">
            <Button
              onClick={handleCancel}
              type={ButtonType.cancel}
              size={ButtonSize.smallWide}
              justify={ButtonJustify.center}
            >
              {t("action_cancel", { ns: "common" })}
            </Button>
            {isCreatingNotification && (
              <>
                <Button
                  onClick={() => {
                    handleGoToNextClick();
                  }}
                  className=" ml-2"
                  type={ButtonType.primary}
                  size={ButtonSize.smallWide}
                  justify={ButtonJustify.center}
                  loading={true}
                  disabled={disabledButton}
                  loadingTxt={t("loading_adding", { ns: "common" })}
                >
                  <span className="sm:block hidden">
                    {t("action_add_notification", { ns: "common" })}
                  </span>
                  <span className="sm:hidden block">
                    {t("action_add_notification_mobile", { ns: "common" })}
                  </span>
                </Button>
              </>
            )}

            {!isCreatingNotification && (
              <>
                <Button
                  onClick={() => {
                    handleGoToNextClick();
                  }}
                  className="ml-2"
                  type={ButtonType.primary}
                  size={ButtonSize.smallWide}
                  justify={ButtonJustify.center}
                  disabled={disabledButton}
                >
                  <span className="sm:block hidden">
                    {t("action_add_notification", { ns: "common" })}
                  </span>
                  <span className="sm:hidden block">
                    {t("action_add_notification_mobile", { ns: "common" })}
                  </span>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
